* {
  box-sizing: border-box;
  color: #333;
}

body {
  background-color: #81a69b;
  background-image: url("http://pets-images.dev-apis.com/pets/wallpaperB.jpg");
  margin: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

.search-params {
  width: 1100px;
  margin: 0 auto;
}

.search-params form {
  width: 360px;
  float: left;
  background: #faeff0;
  border-radius: 6px;
  margin: 0 25px 0 0;
  padding: 35px 15px 15px;
  box-shadow: 0 0 12px #aaa, 0 0 12px #fff;
}

.search {
  width: 715px;
  float: left;
  background: #faeff0;
  border-radius: 6px;
  margin-bottom: 25px;
  padding: 15px;
  box-shadow: 0 0 12px #aaa, 0 0 12px #fff;
}

.details {
  width: 1100px;
  background: #faeff0;
  border-radius: 6px;
  margin: 0 auto 25px;
  padding: 15px;
  box-shadow: 0 0 12px #aaa, 0 0 12px #fff;
}

.pet {
  width: 100%;
  height: 130px;
  border-bottom: 2px solid #333;
  margin: 25px 0;
  display: block;
  overflow: hidden;
}

.pet img {
  width: 100px;
  min-height: 100px;
}

.info {
  float: left;
  width: 80%;
  height: 100px;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 10px;
}

.image-container {
  -webkit-clip-path: circle(50%);
  clip-path: circle(50%);
  width: 100px;
  height: 100px;
  float: left;
  margin: 0 20px 0 10px;
}

.pet h1 {
  white-space: nowrap;
  color: #333;
  width: 95%;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 30px;
  font-weight: normal;
  overflow: hidden;
}

header > a, #root > div > h1 {
  -webkit-filter: brightness(150%);
  filter: brightness(150%);
  width: 279px;
  height: 76px;
  text-indent: -9999px;
  background-image: url("http://static.frontendmasters.com/resources/2019-05-02-complete-intro-react-v5/image-logo.png");
  margin: 20px 0;
  display: inline-block;
  overflow: hidden;
}

.pet h2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 20px;
  font-weight: normal;
}

header a {
  color: #333;
  padding-bottom: 10px;
  font-size: 3px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

header {
  width: 1100px;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  padding: 20px 0 0;
  display: flex;
}

.details p {
  padding: 0 15px;
  line-height: 1.5;
}

.details h1 {
  text-align: center;
  color: #333;
  margin: 5px 0;
  font-size: 60px;
}

.details h2 {
  text-align: center;
  margin: 5px 0 20px;
}

.carousel {
  height: 400px;
  justify-content: space-around;
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.carousel > img {
  max-width: 45%;
  max-height: 400px;
}

.carousel-smaller {
  width: 50%;
}

.carousel-smaller > img {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 2px solid #333;
  border-radius: 50%;
  margin: 15px;
  display: inline-block;
}

.carousel-smaller > img.active {
  opacity: .6;
  border-color: #333;
}

.search-params label {
  width: 60px;
  display: block;
}

.search-params input, .search-params select {
  height: 30px;
  width: 325px;
  margin-bottom: 30px;
  font-size: 18px;
}

.search-params button, #modal button, #modal a, .details button {
  color: #fff;
  cursor: pointer;
  background-color: #ad343e;
  border: 1px solid #333;
  border-radius: 5px;
  margin: 0 auto;
  padding: 5px 25px;
  font-size: 18px;
  display: block;
}

#modal a {
  margin-right: 15px;
  text-decoration: none;
  display: inline-block;
}

.search-params button:hover {
  background-color: #122622;
}

.search-params button:active {
  background-color: #5f1d22;
}

.search-params button:focus {
  border: 1px solid #6495ed;
}

#modal {
  z-index: 10;
  background-color: rgba(0, 0, 0, .9);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#modal:empty {
  display: none;
}

#modal > div {
  max-width: 500px;
  text-align: center;
  background: #faeff0;
  border-radius: 30px;
  padding: 15px;
}

#modal .buttons button {
  margin-right: 15px;
  display: inline-block;
}

@media only screen and (max-width: 1129px) {
  header {
    width: 95%;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 0 0;
    display: flex;
  }

  .search-params {
    width: 95%;
  }

  .search-params form {
    margin-bottom: 20px;
  }

  .search {
    width: 55%;
    float: left;
    border-radius: 9px;
    margin-bottom: 25px;
    padding: 15px;
  }

  .details {
    width: 95%;
    border-radius: 9px;
    margin: 0 auto 25px;
    padding: 15px;
  }

  .info {
    width: 65%;
  }

  .info h1, .info h2 {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media only screen and (max-width: 945px) {
  .search-params {
    width: 95%;
  }

  .search {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .pet {
    height: 200px;
  }

  .info {
    width: 100%;
    text-align: center;
  }

  .image-container {
    float: none;
    margin: 0 auto;
  }

  .carousel-smaller > img {
    width: 60px;
    height: 60px;
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"], input[type="email"], input[type="tel"], input[type="password"], textarea {
  -webkit-appearance: none;
}

input[type="submit"], button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

input, button {
  border: 0;
  margin: 0;
  padding: 0;
}

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

body {
  min-width: 375px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -.01em;
  color: #1a1d1f;
  background: #f4f4f4;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
}

.dark-mode {
  color: #fcfcfc;
  background: #111315;
}

a {
  text-decoration: none;
}

svg, img {
  vertical-align: middle;
}

button {
  background: none;
}

button, input, textarea {
  font-family: Inter, sans-serif;
}

@media only screen and (max-width: 1259px) {
  .desktop-hide {
    display: none !important;
  }
}

.desktop-show {
  display: none !important;
}

@media only screen and (max-width: 1259px) {
  .desktop-show {
    display: block !important;
  }

  .desktop-text-right {
    text-align: right;
  }
}

@media only screen and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}

.tablet-show {
  display: none !important;
}

@media only screen and (max-width: 1023px) {
  .tablet-show {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
}

.mobile-show {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .mobile-show {
    display: block !important;
  }
}

.dark-mode .recharts-rectangle.recharts-tooltip-cursor {
  fill: #272b30;
}

.dark-mode .recharts-curve.recharts-tooltip-cursor {
  stroke: #33383f;
}

.dark-mode .recharts-sector {
  stroke: #1a1d1f;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
}

.h1, .h2, .h3, .h4 {
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.h1 {
  letter-spacing: -.03em;
  font-size: 64px;
  line-height: 1;
}

.h2 {
  letter-spacing: -.03em;
  font-size: 48px;
  line-height: 1;
}

.h3 {
  letter-spacing: -.02em;
  font-size: 40px;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .h3 {
    letter-spacing: -.03em;
    font-size: 32px;
    line-height: 1.25;
  }
}

.h4 {
  letter-spacing: -.03em;
  font-size: 32px;
  line-height: 1.25;
}

[class^="button"] {
  height: 48px;
  text-align: center;
  color: #fcfcfc;
  background: #2a85ff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  transition: all .2s;
  display: inline-flex;
}

[class^="button"]:hover {
  background: #0069f6;
}

[class^="button"].disabled {
  opacity: .5;
  pointer-events: none;
}

[class^="button"] svg {
  fill: #fcfcfc;
  transition: all .2s;
}

[class^="button"]:not([class^="button-square"]) svg:first-child {
  margin-right: 8px;
}

[class^="button"]:not([class^="button-square"]) svg:last-child {
  margin-left: 8px;
}

.button-stroke, .button-stroke-red {
  background: none;
  box-shadow: inset 0 0 0 2px #efefef;
}

.dark-mode .button-stroke, .dark-mode .button-stroke-red {
  box-shadow: inset 0 0 0 2px #272b30;
}

.button-stroke {
  color: #1a1d1f;
}

.button-stroke svg {
  fill: #6f767e;
}

.dark-mode .button-stroke {
  color: #fcfcfc;
}

.button-stroke:hover {
  background: none;
  box-shadow: inset 0 0 0 2px #1a1d1f;
}

.button-stroke:hover svg {
  fill: #1a1d1f;
}

.dark-mode .button-stroke:hover {
  box-shadow: inset 0 0 0 2px #efefef;
}

.dark-mode .button-stroke:hover svg {
  fill: #fcfcfc;
}

.button-stroke.active {
  background: #272b30;
  box-shadow: inset 0 0 0 2px #272b30;
}

.button-stroke.active svg {
  fill: #1a1d1f;
}

.button-stroke-red {
  color: #ff6a55;
}

.button-stroke-red svg {
  fill: #ff6a55;
}

.button-stroke-red:hover, .button-stroke-red.active {
  color: #fcfcfc;
  background: #ff6a55;
  box-shadow: inset 0 0 0 2px #ff6a55;
}

.button-stroke-red:hover svg, .button-stroke-red.active svg {
  fill: #fcfcfc;
}

.button-white {
  color: #1a1d1f;
  background: #fcfcfc;
  box-shadow: inset 0 0 0 2px #efefef;
}

.button-white svg {
  fill: #1a1d1f;
}

.button-white:hover, .button-white.active {
  color: #1a1d1f;
  background: #fff;
  box-shadow: inset 0 0 0 2px #1a1d1f;
}

.button-white:hover svg, .button-white.active svg {
  fill: #1a1d1f;
}

.button-small {
  height: 40px;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 13px;
}

.button-square-stroke {
  width: 48px;
  height: 48px;
  background: none;
  flex: 0 0 48px;
  padding: 0;
  transition: all .2s;
  box-shadow: inset 0 0 0 2px #efefef;
}

.button-square-stroke svg {
  fill: #6f767e;
}

.dark-mode .button-square-stroke {
  box-shadow: inset 0 0 0 2px #272b30;
}

.button-square-stroke:hover {
  background: #2a85ff;
  box-shadow: inset 0 0 0 2px #2a85ff;
}

.button-square-stroke:hover svg {
  fill: #fcfcfc;
}

.button-square-stroke.button-small {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
}

[class^="status"] {
  border-radius: 6px;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  display: inline-block;
}

.status-green {
  color: #83bf6e;
  background: #eafae5;
}

.dark-mode .status-green {
  background: rgba(131, 191, 110, .15);
}

.status-green-dark {
  color: #1a1d1f;
  background: #b5e4ca;
}

.status-red {
  color: #ff6a55;
  background: #ffe7e4;
}

.dark-mode .status-red {
  background: rgba(255, 106, 85, .15);
}

.status-red-dark {
  color: #fcfcfc;
  background: #ffbc99;
}

.status-purple {
  color: #8e59ff;
  background: #cabdff;
}

.status-blue {
  color: #2a85ff;
  background: #b1e5fc;
}

.status-yellow {
  color: #1a1d1f;
  background: #ffd88d;
}

[class^="title"] {
  letter-spacing: -.02em;
  padding-left: 32px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 767px) {
  [class^="title"] {
    font-size: 18px;
  }
}

[class^="title"]:before {
  content: "";
  width: 16px;
  height: 32px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.title-red:before {
  background: #ffbc99;
}

.title-blue:before {
  background: #b1e5fc;
}

.title-purple:before {
  background: #cabdff;
}

.title-yellow:before {
  background: #ffd88d;
}

.title-green:before {
  background: #b5e4ca;
}

.panel {
  align-items: center;
  margin: auto -40px -40px;
  padding: 60px 40px 20px;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1339px) {
  .panel {
    margin: auto -24px -32px;
    padding: 44px 24px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .panel {
    margin: auto -16px -24px;
    padding: 40px 16px 16px;
  }
}

.panel:before {
  content: "";
  z-index: -1;
  background: #fcfcfc;
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 1px 0 #f4f4f4;
}

@media only screen and (max-width: 1339px) {
  .panel:before {
    top: 24px;
  }
}

.dark-mode .panel:before {
  background: #1a1d1f;
  box-shadow: inset 1px 0 #111315;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  transition: border-color .2s;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid rgba(0, 0, 0, 0);
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #efefef;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only {
  display: block;
}

.dark-mode .react-datepicker {
  color: #fcfcfd;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  display: none;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 50%;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}

.dark-mode .react-datepicker__current-month {
  color: #fcfcfd;
}

.dark-mode .react-datepicker__day:hover {
  color: #fcfcfc;
  background-color: #272b30;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 7px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  width: 100%;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
  text-align: left;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: 360px;
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

@media (max-width: 1259px) {
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: 230px;
  }
}

@media (max-width: 767px) {
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: 360px;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  white-space: nowrap;
  color: #6f767e;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
  transition: background .2s, color .2s;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  color: #1a1d1f;
  background-color: #efefef;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #272b30;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  color: #fcfcfc;
  background-color: #272b30;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

.dark-mode .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  color: #fcfcfc;
  background-color: #272b30;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 40px;
  text-align: center;
  margin: 0;
  line-height: 40px;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #efefef;
  border-radius: 50%;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 40px;
  text-align: center;
  border-radius: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  display: inline-block;
}

.react-datepicker__day-name {
  color: #6f767e;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #272b30;
  border-radius: 50%;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1c1e22;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__day--outside-month {
  color: #6f767e;
}

.react-datepicker__day {
  border-radius: 50%;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #efefef;
  border-radius: 50%;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: 50%;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today, .react-datepicker__day--today:hover, .react-datepicker__month-text--today:hover, .react-datepicker__quarter-text--today:hover, .react-datepicker__year-text--today:hover {
  color: #fcfcfc;
  background-color: #2a85ff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #f4f4f4;
  background-color: #272b30;
  border-radius: 50%;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1c1e22;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #3e444c;
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1c1e22;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range) {
  background-color: rgba(39, 43, 48, .5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #efefef;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__day--range-start, .react-datepicker__day--range-end {
  color: #111315;
  position: relative;
  background-color: #f4f4f4 !important;
}

.dark-mode .react-datepicker__day--selected, .dark-mode .react-datepicker__day--in-selecting-range, .dark-mode .react-datepicker__day--in-range, .dark-mode .react-datepicker__month-text--selected, .dark-mode .react-datepicker__month-text--in-selecting-range, .dark-mode .react-datepicker__month-text--in-range, .dark-mode .react-datepicker__quarter-text--selected, .dark-mode .react-datepicker__quarter-text--in-selecting-range, .dark-mode .react-datepicker__quarter-text--in-range, .dark-mode .react-datepicker__year-text--selected, .dark-mode .react-datepicker__year-text--in-selecting-range, .dark-mode .react-datepicker__year-text--in-range {
  background-color: #fcfcfc;
  color: #111315 !important;
}

.dark-mode .react-datepicker__day--today {
  background-color: #2a85ff;
  color: #fcfcfc !important;
}

.dark-mode .react-datepicker__day--selected {
  background-color: #272b30;
  color: #fcfcfc !important;
}

.dark-mode .react-datepicker__day--range-start, .dark-mode .react-datepicker__day--range-end {
  color: #f4f4f4 !important;
  background-color: #111315 !important;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #272b30;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #efefef;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #efefef;
  border: 1px solid #aeaeae;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #272b30;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #efefef;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: rgba(0, 0, 0, .8);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before {
  content: "";
  display: table;
}

.slick-track:after {
  content: "";
  clear: both;
  display: table;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0);
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  transition: all .2s;
  position: absolute;
}

.slick-arrow svg {
  z-index: 2;
  fill: #6f767e;
  transition: fill .2s;
  position: relative;
}

.slick-arrow:hover {
  box-shadow: inset 0 0 0 2px #272b30;
}

.dark-mode .slick-arrow:hover {
  box-shadow: inset 0 0 0 2px #efefef;
}

.dark-mode .slick-arrow:hover svg {
  fill: #272b30;
}

@media only screen and (max-width: 767px) {
  .photo-slider {
    padding-bottom: 64px;
  }
}

.photo-slider .slick-list {
  overflow: visible;
}

.photo-slider .slick-track {
  display: flex;
}

.photo-slider .slick-slide {
  height: auto;
  opacity: 0;
  visibility: hidden;
  padding: 0 16px;
  transition: all .4s;
}

@media only screen and (max-width: 767px) {
  .photo-slider .slick-slide {
    padding: 0;
  }
}

.photo-slider .slick-slide.slick-active {
  opacity: 1;
  visibility: visible;
}

.photo-slider .slick-slide > div {
  height: 100%;
  flex-grow: 1;
  display: flex;
}

.photo-slider .slick-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .photo-slider .slick-arrow {
    top: auto;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.photo-slider .slick-prev {
  left: -56px;
}

@media only screen and (max-width: 767px) {
  .photo-slider .slick-prev {
    left: calc(50% - 48px);
  }
}

.photo-slider .slick-next {
  right: -56px;
}

@media only screen and (max-width: 767px) {
  .photo-slider .slick-next {
    right: calc(50% - 48px);
  }
}

@media only screen and (max-width: 1023px) {
  .products-slider {
    padding-bottom: 62px;
  }
}

@media only screen and (max-width: 767px) {
  .products-slider .slick-list {
    margin: 0 -296px 0 -8px;
  }
}

.products-slider .slick-slide {
  padding: 0 12px;
}

@media only screen and (max-width: 767px) {
  .products-slider .slick-slide {
    padding: 0 8px;
  }
}

.products-slider .slick-arrow {
  visibility: hidden;
  opacity: 0;
  bottom: 0;
}

@media only screen and (max-width: 1023px) {
  .products-slider .slick-arrow {
    visibility: visible;
    opacity: 1;
  }
}

.dark-mode .products-slider .slick-arrow:hover {
  box-shadow: inset 0 0 0 2px #272b30;
}

.dark-mode .products-slider .slick-arrow:hover svg {
  fill: #fcfcfc;
}

.products-slider .slick-prev {
  left: calc(50% - 48px);
}

.products-slider .slick-next {
  right: calc(50% - 48px);
}

.rdw-editor-wrapper .rdw-option-wrapper {
  min-width: 20px;
  height: 20px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  margin: 0 2px;
  transition: border-color .2s;
}

@media only screen and (max-width: 767px) {
  .rdw-editor-wrapper .rdw-option-wrapper {
    min-width: 16px;
    height: 16px;
  }
}

.dark-mode .rdw-editor-wrapper .rdw-option-wrapper {
  background: #bfc3c7;
}

.rdw-editor-wrapper .rdw-option-wrapper:hover {
  box-shadow: none;
  border-color: #6f767e;
}

.rdw-editor-wrapper .rdw-option-wrapper.rdw-option-active {
  box-shadow: none;
  border-color: #2a85ff;
}

.dark-mode .rdw-editor-wrapper .rdw-option-wrapper.rdw-option-active {
  border-color: #2a85ff;
  box-shadow: inset 0 0 0 1px #2a85ff;
}

.rdw-editor-wrapper .rdw-history-wrapper {
  margin-left: auto;
}

.rdw-editor-wrapper .public-DraftStyleDefault-block {
  margin: 12px 16px;
}

.rdw-editor-wrapper .rdw-emoji-modal, .rdw-editor-wrapper .rdw-link-modal {
  box-shadow: 0 10px 25px rgba(17, 19, 21, .1);
}

.app {
  text-align: center;
  padding: 40px;
}

.container {
  width: 50%;
  margin: auto;
}

.ReactTags__tags {
  background: #f4f4f4;
  border-radius: 12px;
  padding: 0 0 5px;
  position: relative;
}

.dark-mode .ReactTags__tags {
  background: #272b30;
}

.ReactTags__clearAll {
  cursor: pointer;
  height: 40px;
  color: #fff;
  background: #ff6a55;
  border: none;
  border-radius: 12px;
  margin: 10px;
  padding: 0 10px;
  font-size: 14px;
}

.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField, .ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 40px;
  box-shadow: none;
  min-width: 240px;
  color: #1a1d1f;
  background: none;
  border: none;
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
}

.dark-mode .ReactTags__tagInput input.ReactTags__tagInputField, .dark-mode .ReactTags__tagInput input.ReactTags__tagInputField:focus {
  color: #fcfcfc;
}

.ReactTags__tagInput input.ReactTags__tagInputField::-webkit-input-placeholder {
  color: #9a9fa5;
}

.ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
  color: #9a9fa5;
}

.dark-mode .ReactTags__tagInput input.ReactTags__tagInputField::-webkit-input-placeholder {
  color: #6f767e;
}

.dark-mode .ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
  color: #6f767e;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

.ReactTags__selected span.ReactTags__tag {
  color: #fcfcfc;
  background: #2a85ff;
  border-radius: 8px;
  align-items: center;
  margin: 5px 5px 0;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 700;
  display: inline-flex;
}

.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  width: 200px;
  background: #fff;
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  cursor: pointer;
  background: #b1e5fc;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #efefef;
  margin: 0;
  padding: 5px 10px;
}

.ReactTags__suggestions li mark {
  background: none;
  font-weight: 600;
  text-decoration: underline;
}

.ReactTags__remove {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #fff;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 0 0-1.414-1.414L12 10.586 6.707 5.293z' fill='%23fcfcfc'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  border: none;
  margin: 0 0 0 8px;
  font-size: 0;
  transition: -webkit-transform .2s, transform .2s;
}

.ReactTags__remove:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.L7AKva_page {
  padding: 96px 0 0 340px;
}

@media only screen and (max-width: 1339px) {
  .L7AKva_page {
    padding: 80px 0 0 300px;
  }
}

@media only screen and (max-width: 1259px) {
  .L7AKva_page {
    padding-left: 96px;
  }
}

@media only screen and (max-width: 1023px) {
  .L7AKva_page {
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .L7AKva_page {
    padding-left: 0;
  }

  .L7AKva_sidebar {
    transition: -webkit-transform .3s, transform .3s;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .L7AKva_sidebar.L7AKva_visible {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.L7AKva_inner {
  min-height: calc(100vh - 96px);
  flex-direction: column;
  padding: 40px;
  display: flex;
}

@media only screen and (max-width: 1339px) {
  .L7AKva_inner {
    padding: 32px 24px;
  }
}

@media only screen and (max-width: 767px) {
  .L7AKva_inner {
    padding: 24px 16px;
  }
}

.L7AKva_container {
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
}

.L7AKva_container.L7AKva_wide {
  max-width: 100%;
}

.L7AKva_title {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .L7AKva_title {
    margin-bottom: 16px;
  }
}

.msqaBa_sidebar {
  width: 340px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #fcfcfc;
  flex-direction: column;
  padding: 24px;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.msqaBa_sidebar::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1339px) {
  .msqaBa_sidebar {
    width: 300px;
    padding: 16px;
  }
}

@media only screen and (max-width: 1259px) {
  .msqaBa_sidebar {
    z-index: 14;
    width: 96px;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_sidebar {
    width: 100%;
    align-items: stretch;
  }
}

.dark-mode .msqaBa_sidebar {
  background: #1a1d1f;
}

@media only screen and (max-width: 1259px) {
  .msqaBa_sidebar.msqaBa_active {
    width: 300px;
    align-items: stretch;
    box-shadow: 4px 0 32px rgba(17, 19, 21, .05);
  }

  .msqaBa_sidebar.msqaBa_active .msqaBa_item, .msqaBa_sidebar.msqaBa_active .msqaBa_link {
    width: 100%;
    font-size: 15px;
  }

  .msqaBa_sidebar.msqaBa_active .msqaBa_item svg, .msqaBa_sidebar.msqaBa_active .msqaBa_link svg {
    margin-right: 12px;
  }

  .msqaBa_sidebar.msqaBa_active .msqaBa_counter {
    display: block;
  }

  .msqaBa_sidebar.msqaBa_active .msqaBa_toggle {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .msqaBa_sidebar.msqaBa_active .msqaBa_toggle svg {
    fill: #1a1d1f;
  }

  .msqaBa_sidebar.msqaBa_active .msqaBa_toggle svg:first-child {
    display: none;
  }

  .msqaBa_sidebar.msqaBa_active .msqaBa_toggle svg:nth-child(2) {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_sidebar.msqaBa_active {
    width: 100%;
  }
}

.msqaBa_logo {
  width: 48px;
  margin-bottom: 48px;
  display: block;
}

@media only screen and (max-width: 1339px) {
  .msqaBa_logo {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_logo {
    margin-left: auto;
  }
}

.msqaBa_logo img {
  width: 100%;
}

.msqaBa_menu {
  flex-direction: column;
  margin-bottom: auto;
  display: flex;
}

.msqaBa_item, .msqaBa_dropdown {
  margin-bottom: 8px;
}

.msqaBa_item, .msqaBa_link {
  width: 100%;
  height: 48px;
  white-space: nowrap;
  color: #6f767e;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 12px;
  align-items: center;
  padding: 0 12px;
  font-size: 15px;
  font-weight: 600;
  transition: background .2s, box-shadow .2s, color .2s;
  display: flex;
}

@media only screen and (max-width: 1259px) {
  .msqaBa_item, .msqaBa_link {
    width: 48px;
    font-size: 0;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_item, .msqaBa_link {
    width: 100%;
    font-size: 15px;
  }
}

.msqaBa_item svg, .msqaBa_link svg {
  fill: #6f767e;
  margin-right: 12px;
  transition: fill .2s;
}

@media only screen and (max-width: 1259px) {
  .msqaBa_item svg, .msqaBa_link svg {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_item svg, .msqaBa_link svg {
    margin-right: 12px;
  }
}

.msqaBa_item:hover, .msqaBa_item.msqaBa_active, .msqaBa_link:hover, .msqaBa_link.msqaBa_active {
  color: #1a1d1f;
}

.msqaBa_item:hover svg, .msqaBa_item.msqaBa_active svg, .msqaBa_link:hover svg, .msqaBa_link.msqaBa_active svg {
  fill: #1a1d1f;
}

.dark-mode .msqaBa_item:hover, .dark-mode .msqaBa_item.msqaBa_active, .dark-mode .msqaBa_link:hover, .dark-mode .msqaBa_link.msqaBa_active {
  color: #fcfcfc;
}

.dark-mode .msqaBa_item:hover svg, .dark-mode .msqaBa_item.msqaBa_active svg, .dark-mode .msqaBa_link:hover svg, .dark-mode .msqaBa_link.msqaBa_active svg {
  fill: #fcfcfc;
}

.msqaBa_item.msqaBa_active, .msqaBa_link.msqaBa_active {
  background: #efefef;
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .05), inset 0 1px 1px #fff;
}

.dark-mode .msqaBa_item.msqaBa_active, .dark-mode .msqaBa_link.msqaBa_active {
  background: #272b30;
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .4), inset 0 1px 1px rgba(255, 255, 255, .11);
}

.msqaBa_toggle {
  width: 48px;
  height: 48px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: none;
}

@media only screen and (max-width: 1259px) {
  .msqaBa_toggle {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_toggle {
    display: none;
  }
}

.msqaBa_toggle svg {
  fill: #6f767e;
}

.msqaBa_toggle svg:nth-child(2), .msqaBa_close {
  display: none;
}

@media only screen and (max-width: 767px) {
  .msqaBa_close {
    width: 48px;
    height: 48px;
    display: block;
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .msqaBa_close svg {
    fill: #1a1d1f;
  }

  .dark-mode .msqaBa_close svg {
    fill: #fcfcfc;
  }
}

.msqaBa_foot {
  border-top: 2px solid #f4f4f4;
  margin-top: 24px;
  padding-top: 18px;
}

@media only screen and (max-width: 1259px) {
  .msqaBa_foot {
    margin-top: 12px;
    padding-top: 12px;
  }
}

.dark-mode .msqaBa_foot {
  border-color: #272b30;
}

.msqaBa_theme {
  margin-top: 16px;
}

.msqaBa_counter {
  min-width: 24px;
  text-align: center;
  color: #1a1d1f;
  background: #cabdff;
  border-radius: 6px;
  flex-shrink: 0;
  margin-left: auto;
  line-height: 24px;
}

@media only screen and (max-width: 1259px) {
  .msqaBa_counter {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_counter {
    display: block;
  }
}

@media only screen and (max-width: 1259px) {
  .msqaBa_overlay {
    z-index: 12;
    visibility: hidden;
    opacity: 0;
    background: rgba(244, 244, 244, .8);
    transition: all .2s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .dark-mode .msqaBa_overlay {
    background: rgba(39, 43, 48, .9);
  }

  .msqaBa_overlay.msqaBa_active {
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .msqaBa_overlay {
    display: none;
  }
}

.xSWPDa_theme {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  display: block;
  position: relative;
}

@media only screen and (max-width: 1259px) {
  .xSWPDa_theme.xSWPDa_wide .xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:first-child {
    display: flex;
  }

  .xSWPDa_theme.xSWPDa_wide .xSWPDa_inner {
    border-radius: 20px;
    padding: 4px 2px;
    display: flex;
  }

  .xSWPDa_theme.xSWPDa_wide .xSWPDa_box {
    height: 32px;
    border-radius: 16px;
    font-size: 15px;
    display: flex;
  }

  .xSWPDa_theme.xSWPDa_wide .xSWPDa_box svg {
    margin-right: 8px;
  }
}

.xSWPDa_input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.xSWPDa_input:checked + .xSWPDa_inner {
  background: #111315;
}

.xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:first-child {
  box-shadow: none;
  color: #6f767e;
  background: none;
}

@media only screen and (max-width: 1259px) {
  .xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:first-child {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:first-child {
    display: flex;
  }
}

.xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:first-child svg {
  fill: #6f767e;
}

.xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:first-child:hover {
  color: #fcfcfc;
}

.xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:first-child:hover svg {
  fill: #fcfcfc;
}

.xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:nth-child(2) {
  color: #fcfcfc;
  background: #272b30;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, .25), inset 0 -1px 1px rgba(0, 0, 0, .49), inset 0 2px 1px rgba(255, 255, 255, .06);
}

.xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:nth-child(2) svg {
  fill: #fcfcfc;
}

@media only screen and (max-width: 1259px) {
  .xSWPDa_input:checked + .xSWPDa_inner .xSWPDa_box:nth-child(2) {
    display: flex;
  }
}

.xSWPDa_inner {
  background: #f4f4f4;
  border-radius: 20px;
  padding: 4px 2px;
  transition: background .2s;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1259px) {
  .xSWPDa_inner {
    border-radius: 50%;
    padding: 2px;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .xSWPDa_inner {
    border-radius: 20px;
    padding: 4px 2px;
    display: flex;
  }
}

.xSWPDa_box {
  height: 32px;
  border-radius: 16px;
  flex: 0 0 calc(50% - 4px);
  justify-content: center;
  align-items: center;
  margin: 2px;
  font-size: 15px;
  transition: background .2s, box-shadow .2s, color .2s;
  display: flex;
}

@media only screen and (max-width: 1259px) {
  .xSWPDa_box {
    height: 40px;
    border-radius: 50%;
    font-size: 0;
  }
}

@media only screen and (max-width: 767px) {
  .xSWPDa_box {
    height: 32px;
    border-radius: 16px;
    font-size: 15px;
  }
}

.xSWPDa_box svg {
  margin-right: 8px;
  transition: fill .2s;
}

@media only screen and (max-width: 1259px) {
  .xSWPDa_box svg {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .xSWPDa_box svg {
    margin-right: 8px;
  }
}

.xSWPDa_box:first-child {
  background: #fcfcfc;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, .25), inset 0 -1px 1px rgba(0, 0, 0, .04), inset 0 2px rgba(255, 255, 255, .25);
}

.xSWPDa_box:nth-child(2) {
  color: #6f767e;
}

@media only screen and (max-width: 1259px) {
  .xSWPDa_box:nth-child(2) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .xSWPDa_box:nth-child(2) {
    display: flex;
  }
}

.xSWPDa_box:nth-child(2) svg {
  fill: #6f767e;
}

.xSWPDa_box:hover {
  color: #1a1d1f;
}

.xSWPDa_box:hover svg {
  fill: #1a1d1f;
}

.EL-OLq_dropdown.EL-OLq_active .EL-OLq_head svg:last-child {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.EL-OLq_dropdown.EL-OLq_active .EL-OLq_body {
  display: block;
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_dropdown.EL-OLq_active .EL-OLq_body {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .EL-OLq_dropdown.EL-OLq_active .EL-OLq_body {
    display: block;
  }
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_dropdown.EL-OLq_wide .EL-OLq_head {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1259px) and (max-width: 1259px) {
  .EL-OLq_dropdown.EL-OLq_wide .EL-OLq_head svg:first-child {
    margin-right: 12px;
  }

  .EL-OLq_dropdown.EL-OLq_wide .EL-OLq_head svg:last-child {
    display: inline-block;
  }
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_dropdown.EL-OLq_wide .EL-OLq_add {
    display: flex;
  }

  .EL-OLq_dropdown.EL-OLq_wide.EL-OLq_active .EL-OLq_body {
    display: block;
  }
}

.EL-OLq_top {
  position: relative;
}

.EL-OLq_top.EL-OLq_active .EL-OLq_head {
  color: #1a1d1f;
  background: #efefef;
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .05), inset 0 1px 1px #fff;
}

.dark-mode .EL-OLq_top.EL-OLq_active .EL-OLq_head {
  color: #fcfcfc;
  background: #272b30;
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .4), inset 0 1px 1px rgba(255, 255, 255, .11);
}

.EL-OLq_head {
  width: 100%;
  height: 48px;
  white-space: nowrap;
  color: #6f767e;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 12px;
  align-items: center;
  padding: 0 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  transition: background .2s, box-shadow .2s, color .2s;
  display: flex;
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_head {
    font-size: 0;
  }
}

@media only screen and (max-width: 767px) {
  .EL-OLq_head {
    font-size: 15px;
  }
}

.EL-OLq_head:hover {
  color: #1a1d1f;
}

.EL-OLq_head:hover svg {
  fill: #1a1d1f;
}

.dark-mode .EL-OLq_head:hover {
  color: #fcfcfc;
}

.dark-mode .EL-OLq_head:hover svg {
  fill: #fcfcfc;
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_head.EL-OLq_active:not(.EL-OLq_wide) {
    color: #1a1d1f;
    background: #efefef;
    box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .05), inset 0 1px 1px #fff;
  }
}

@media only screen and (max-width: 767px) {
  .EL-OLq_head.EL-OLq_active:not(.EL-OLq_wide) {
    box-shadow: none;
    color: #6f767e;
    background: none;
  }
}

@media only screen and (max-width: 1259px) {
  .dark-mode .EL-OLq_head.EL-OLq_active:not(.EL-OLq_wide) {
    color: #fcfcfc;
    background: #272b30;
    box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .4), inset 0 1px 1px rgba(255, 255, 255, .11);
  }

  .dark-mode .EL-OLq_head.EL-OLq_active:not(.EL-OLq_wide) svg {
    fill: #fcfcfc;
  }
}

@media only screen and (max-width: 767px) {
  .dark-mode .EL-OLq_head.EL-OLq_active:not(.EL-OLq_wide) {
    box-shadow: none;
    color: #6f767e;
    background: none;
  }

  .dark-mode .EL-OLq_head.EL-OLq_active:not(.EL-OLq_wide) svg {
    fill: #6f767e;
  }
}

.EL-OLq_head svg {
  fill: #6f767e;
  flex-shrink: 0;
  transition: -webkit-transform .2s, transform .2s, fill .2s;
}

.EL-OLq_head svg:first-child {
  margin-right: 12px;
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_head svg:first-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .EL-OLq_head svg:first-child {
    margin-right: 12px;
  }
}

.EL-OLq_head svg:last-child {
  margin-left: auto;
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_head svg:last-child {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .EL-OLq_head svg:last-child {
    display: inline-block;
  }
}

.EL-OLq_add {
  width: 24px;
  height: 24px;
  border: 2px solid #efefef;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 0;
  transition: border-color .2s;
  display: flex;
  position: absolute;
  top: 50%;
  right: 48px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 1259px) {
  .EL-OLq_add {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .EL-OLq_add {
    display: flex;
  }
}

.dark-mode .EL-OLq_add {
  border-color: #33383f;
}

.EL-OLq_add svg {
  fill: #6f767e;
  transition: fill .2s;
}

.EL-OLq_add:hover {
  border-color: #1a1d1f;
}

.EL-OLq_add:hover svg {
  fill: #1a1d1f;
}

.dark-mode .EL-OLq_add:hover {
  border-color: #9a9fa5;
}

.dark-mode .EL-OLq_add:hover svg {
  fill: #9a9fa5;
}

.EL-OLq_add.EL-OLq_active {
  border-color: #9a9fa5;
}

.EL-OLq_add.EL-OLq_active svg {
  fill: #9a9fa5;
}

.EL-OLq_body {
  padding-left: 36px;
  display: none;
  position: relative;
}

.EL-OLq_body:before {
  content: "";
  width: 2px;
  background: #efefef;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 32px;
  left: 23px;
}

.dark-mode .EL-OLq_body:before {
  background: #272b30;
}

.EL-OLq_link {
  height: 48px;
  color: #6f767e;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 12px;
  align-items: center;
  padding: 0 12px;
  transition: background .2s, box-shadow .2s, color .2s;
  display: flex;
  position: relative;
}

.EL-OLq_link:before {
  content: "";
  width: 12px;
  height: 12px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  position: absolute;
  top: 12px;
  left: -13px;
}

.dark-mode .EL-OLq_link:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23272B30' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
}

.EL-OLq_link svg {
  opacity: 0;
  fill: #1a1d1f;
  margin-left: auto;
  transition: opacity .2s;
}

.dark-mode .EL-OLq_link svg {
  fill: #fcfcfc;
}

.EL-OLq_link:hover {
  color: #1a1d1f;
}

.dark-mode .EL-OLq_link:hover {
  color: #fcfcfc;
}

.EL-OLq_link.EL-OLq_active {
  color: #1a1d1f;
  background: #efefef;
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .05), inset 0 1px 1px #fff;
}

.dark-mode .EL-OLq_link.EL-OLq_active {
  color: #fcfcfc;
  background: #272b30;
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .4), inset 0 1px 1px rgba(255, 255, 255, .11);
}

.EL-OLq_link.EL-OLq_active svg {
  opacity: 1;
}

.EL-OLq_counter {
  min-width: 24px;
  text-align: center;
  color: #1a1d1f;
  border-radius: 6px;
  flex-shrink: 0;
  margin-left: auto;
  line-height: 24px;
}

.cGaMfa_help {
  z-index: 15;
  width: 340px;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  flex-direction: column;
  padding: 24px 12px;
  transition: -webkit-transform .3s, transform .3s;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@media only screen and (max-width: 1339px) {
  .cGaMfa_help {
    width: 320px;
    padding: 16px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .cGaMfa_help {
    width: 100%;
  }
}

.dark-mode .cGaMfa_help {
  background: #111315;
}

.cGaMfa_help.cGaMfa_active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@media only screen and (max-width: 1259px) {
  .cGaMfa_help.cGaMfa_active {
    box-shadow: 4px 0 32px rgba(17, 19, 21, .05);
  }
}

.cGaMfa_head {
  align-items: center;
  margin-bottom: 12px;
  padding: 12px;
  display: flex;
}

.cGaMfa_head > svg {
  fill: #1a1d1f;
  margin-right: 12px;
}

.dark-mode .cGaMfa_head > svg {
  fill: #fcfcfc;
}

.cGaMfa_close {
  margin-left: auto;
}

.cGaMfa_close svg {
  fill: #1a1d1f;
  transition: fill .2s;
}

.dark-mode .cGaMfa_close svg {
  fill: #fcfcfc;
}

.cGaMfa_close:hover svg {
  fill: #2a85ff;
}

.cGaMfa_list {
  border-top: 1px solid #efefef;
  margin-bottom: auto;
  padding: 24px 0;
}

.dark-mode .cGaMfa_list {
  border-color: rgba(111, 118, 126, .2);
}

.cGaMfa_menu {
  flex-direction: column;
  display: flex;
}

.cGaMfa_link {
  height: 48px;
  color: #6f767e;
  border-radius: 12px;
  align-items: center;
  padding: 0 12px;
  transition: all .2s;
  display: flex;
}

.cGaMfa_link svg {
  fill: #6f767e;
  transition: fill .2s;
}

.cGaMfa_link > svg {
  margin-right: 12px;
}

.cGaMfa_link:hover {
  color: #1a1d1f;
}

.cGaMfa_link:hover svg {
  fill: #1a1d1f;
}

.dark-mode .cGaMfa_link:hover {
  color: #fcfcfc;
}

.dark-mode .cGaMfa_link:hover svg {
  fill: #fcfcfc;
}

.cGaMfa_link:not(:last-child) {
  margin-bottom: 8px;
}

@media only screen and (max-width: 1339px) {
  .cGaMfa_link:not(:last-child) {
    margin-bottom: 4px;
  }
}

.cGaMfa_arrow {
  margin-left: auto;
}

.cGaMfa_counter {
  min-width: 24px;
  text-align: center;
  color: #1a1d1f;
  background: #ffbc99;
  border-radius: 6px;
  flex-shrink: 0;
  margin-left: auto;
  line-height: 24px;
}

.cGaMfa_overlay {
  z-index: 14;
  visibility: hidden;
  opacity: 0;
  background: rgba(244, 244, 244, .8);
  transition: all .2s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dark-mode .cGaMfa_overlay {
  background: rgba(39, 43, 48, .9);
}

.cGaMfa_overlay.cGaMfa_active {
  visibility: visible;
  opacity: 1;
}

.MmPyoW_item {
  cursor: pointer;
  border-radius: 12px;
  align-items: center;
  padding: 12px;
  transition: all .2s;
  display: flex;
}

@media only screen and (max-width: 1339px) {
  .MmPyoW_item {
    padding: 6px;
  }
}

.MmPyoW_item:hover {
  background: #f4f4f4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

.dark-mode .MmPyoW_item:hover {
  box-shadow: none;
  background: #1a1d1f;
}

.MmPyoW_item:not(:last-child) {
  margin-bottom: 8px;
}

.MmPyoW_preview {
  width: 96px;
  height: 72px;
  flex-shrink: 0;
  margin-right: 16px;
}

.MmPyoW_preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.MmPyoW_title {
  margin-bottom: 8px;
}

.MmPyoW_line {
  align-items: center;
  display: flex;
}

.MmPyoW_status {
  color: #1a1d1f;
  margin-right: 4px;
}

.MmPyoW_user {
  border: 1px solid #efefef;
  border-radius: 4px;
  align-items: center;
  padding: 1px 8px 1px 1px;
  display: flex;
}

.dark-mode .MmPyoW_user {
  border-color: #272b30;
}

.MmPyoW_avatar {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 4px;
  font-size: 0;
}

.MmPyoW_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.MmPyoW_time {
  color: #6f767e;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33333;
}

.Nr45ka_header {
  z-index: 10;
  background: #fcfcfc;
  align-items: center;
  padding: 24px 40px;
  display: flex;
  position: fixed;
  top: 0;
  left: 340px;
  right: 0;
  box-shadow: inset 1px 0 #f4f4f4, inset 0 -1px #efefef;
}

@media only screen and (max-width: 1339px) {
  .Nr45ka_header {
    padding: 16px 24px;
    left: 300px;
  }
}

@media only screen and (max-width: 1259px) {
  .Nr45ka_header {
    left: 96px;
  }
}

@media only screen and (max-width: 767px) {
  .Nr45ka_header {
    padding: 16px 24px 16px 16px;
    left: 0;
  }
}

.dark-mode .Nr45ka_header {
  background: #1a1d1f;
  box-shadow: inset 1px 0 #111315, inset 0 -1px #111315;
}

.Nr45ka_burger {
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  display: none;
}

@media only screen and (max-width: 767px) {
  .Nr45ka_burger {
    display: flex;
  }
}

.Nr45ka_burger:before, .Nr45ka_burger:after {
  content: "";
  width: 16px;
  height: 2px;
  background: #6f767e;
  border-radius: 2px;
}

.Nr45ka_burger:before {
  margin-bottom: 6px;
}

.Nr45ka_control {
  align-items: center;
  margin-left: auto;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Nr45ka_control {
    margin-left: 0;
  }
}

.Nr45ka_control .Nr45ka_button:not(:last-child), .Nr45ka_control > div:not(:last-child), .Nr45ka_buttonSearch:not(:last-child) {
  margin-right: 24px;
}

.Nr45ka_buttonSearch {
  width: 48px;
  height: 48px;
  display: none;
}

@media only screen and (max-width: 767px) {
  .Nr45ka_buttonSearch {
    display: inline-block;
  }
}

.Nr45ka_buttonSearch svg {
  fill: #6f767e;
  transition: fill .2s;
}

.Nr45ka_buttonSearch.Nr45ka_active svg {
  fill: #1a1d1f;
}

.dark-mode .Nr45ka_buttonSearch.Nr45ka_active svg {
  fill: #fcfcfc;
}

@media only screen and (max-width: 1023px) {
  .Nr45ka_control .Nr45ka_button {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .Nr45ka_search {
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
  }

  .Nr45ka_search.Nr45ka_visible {
    visibility: visible;
    opacity: 1;
  }
}

.Nr45ka_btns {
  align-items: center;
  margin-left: auto;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Nr45ka_btns {
    margin-left: 0;
  }
}

.Nr45ka_link {
  color: #1a1d1f;
  margin-right: 32px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  transition: color .2s;
}

@media only screen and (max-width: 767px) {
  .Nr45ka_link {
    margin-right: 24px;
  }
}

.dark-mode .Nr45ka_link {
  color: #fcfcfc;
}

.Nr45ka_link:hover {
  color: #2a85ff;
}

.atK7nq_messages {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .atK7nq_messages {
    position: static;
  }
}

.atK7nq_messages.atK7nq_active .atK7nq_head svg {
  fill: #1a1d1f;
}

.dark-mode .atK7nq_messages.atK7nq_active .atK7nq_head svg {
  fill: #fcfcfc;
}

.atK7nq_messages.atK7nq_active .atK7nq_body {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.atK7nq_head {
  width: 48px;
  height: 48px;
  position: relative;
}

.atK7nq_head svg {
  fill: #6f767e;
  transition: fill .2s;
}

.atK7nq_head:hover svg {
  fill: #1a1d1f;
}

.dark-mode .atK7nq_head:hover svg {
  fill: #fcfcfc;
}

.atK7nq_head.atK7nq_active:before {
  content: "";
  width: 14px;
  height: 14px;
  background: #ff6a55;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.dark-mode .atK7nq_head.atK7nq_active:before {
  border-color: #1a1d1f;
}

.atK7nq_body {
  width: 392px;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  padding: 12px 24px 24px;
  transition: all .2s;
  position: absolute;
  top: calc(100% + 18px);
  right: -12px;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: 0 40px 64px -12px rgba(0, 0, 0, .08), 0 0 14px -4px rgba(0, 0, 0, .05), 0 32px 48px -8px rgba(0, 0, 0, .1);
}

@media only screen and (max-width: 1339px) {
  .atK7nq_body {
    top: calc(100% + 15px);
  }
}

@media only screen and (max-width: 767px) {
  .atK7nq_body {
    width: auto;
    padding: 12px 16px 20px;
    top: 100%;
    left: 16px;
    right: 16px;
  }
}

.dark-mode .atK7nq_body {
  background: #111315;
  border-color: #272b30;
}

.atK7nq_body:before {
  content: "";
  width: 20px;
  height: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  position: absolute;
  bottom: 100%;
  right: 26px;
}

@media only screen and (max-width: 767px) {
  .atK7nq_body:before {
    right: 165px;
  }
}

.dark-mode .atK7nq_body:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E");
}

.atK7nq_top {
  align-items: center;
  margin: 0 -12px 12px 0;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .atK7nq_top {
    margin-right: -6px;
  }
}

.atK7nq_title {
  letter-spacing: -.02em;
  margin-right: auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .atK7nq_title {
    font-size: 18px;
  }
}

.atK7nq_actions .atK7nq_actionsHead {
  background: #efefef;
}

.atK7nq_actions .atK7nq_actionsHead svg {
  fill: #1a1d1f;
}

.dark-mode .atK7nq_actions .atK7nq_actionsHead {
  background: #272b30;
}

.dark-mode .atK7nq_actions .atK7nq_actionsHead svg {
  fill: #fcfcfc;
}

.atK7nq_list {
  margin: 0 -12px;
}

@media only screen and (max-width: 767px) {
  .atK7nq_list {
    margin: 0 -8px;
  }
}

.atK7nq_button {
  width: 100%;
  margin-top: 24px;
}

.uNlaLW_item {
  border-radius: 12px;
  align-items: center;
  padding: 12px;
  transition: background .2s;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .uNlaLW_item {
    padding: 8px;
  }
}

.uNlaLW_item:hover {
  background: #efefef;
}

.dark-mode .uNlaLW_item:hover {
  background: #272b30;
}

@media only screen and (max-width: 767px) {
  .uNlaLW_item:hover {
    background: none;
  }
}

.uNlaLW_item:hover .uNlaLW_avatar.uNlaLW_online:before {
  border-color: #efefef;
}

@media only screen and (max-width: 767px) {
  .uNlaLW_item:hover .uNlaLW_avatar.uNlaLW_online:before {
    border-color: #fff;
  }
}

.dark-mode .uNlaLW_item:hover .uNlaLW_avatar.uNlaLW_online:before {
  border-color: #272b30;
}

@media only screen and (max-width: 767px) {
  .dark-mode .uNlaLW_item:hover .uNlaLW_avatar.uNlaLW_online:before {
    border-color: #111315;
  }
}

.uNlaLW_item:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #9a9fa5;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 12px;
}

@media only screen and (max-width: 767px) {
  .uNlaLW_item:after {
    top: 14px;
  }
}

.uNlaLW_item.uNlaLW_new:after {
  background: #2a85ff;
}

.uNlaLW_item:not(:last-child) {
  margin-bottom: 8px;
}

.uNlaLW_item:not(:last-child):before {
  content: "";
  height: 1px;
  background: #efefef;
  position: absolute;
  bottom: 0;
  left: 12px;
  right: 12px;
}

.dark-mode .uNlaLW_item:not(:last-child):before {
  background: none;
}

@media only screen and (max-width: 767px) {
  .uNlaLW_item:nth-child(n+3) {
    display: none;
  }
}

@media only screen and (max-width: 639px) {
  .uNlaLW_item:nth-child(n+3) {
    display: flex;
  }

  .uNlaLW_item:nth-child(n+6) {
    display: none;
  }
}

.uNlaLW_avatar {
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .uNlaLW_avatar {
    width: 48px;
    height: 48px;
  }
}

.uNlaLW_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.uNlaLW_avatar.uNlaLW_online:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #83bf6e;
  border: 4px solid #fff;
  border-radius: 50%;
  transition: border-color .2s;
  position: absolute;
  top: -2px;
  left: -2px;
}

.dark-mode .uNlaLW_avatar.uNlaLW_online:before {
  border-color: #111315;
}

.uNlaLW_details {
  width: calc(100% - 56px);
  flex: 0 0 calc(100% - 56px);
  padding-left: 12px;
}

.uNlaLW_line {
  align-items: center;
  margin-bottom: 4px;
  padding-right: 24px;
  display: flex;
}

.uNlaLW_subtitle {
  color: #1a1d1f;
  margin-right: auto;
  font-weight: 700;
}

.dark-mode .uNlaLW_subtitle {
  color: #fcfcfc;
}

.uNlaLW_time {
  color: #9a9fa5;
  margin-left: 16px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23077;
}

.uNlaLW_content {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #6f767e;
  padding-right: 40px;
  font-weight: 500;
  overflow: hidden;
}

.-\31 oVWW_notification {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .-\31 oVWW_notification {
    position: static;
  }
}

.-\31 oVWW_notification.-\31 oVWW_active .-\31 oVWW_head svg {
  fill: #1a1d1f;
}

.dark-mode .-\31 oVWW_notification.-\31 oVWW_active .-\31 oVWW_head svg {
  fill: #fcfcfc;
}

.-\31 oVWW_notification.-\31 oVWW_active .-\31 oVWW_body {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.-\31 oVWW_head {
  width: 48px;
  height: 48px;
  position: relative;
}

.-\31 oVWW_head svg {
  fill: #6f767e;
  transition: fill .2s;
}

.-\31 oVWW_head:hover svg {
  fill: #1a1d1f;
}

.dark-mode .-\31 oVWW_head:hover svg {
  fill: #fcfcfc;
}

.-\31 oVWW_head.-\31 oVWW_active:before {
  content: "";
  width: 14px;
  height: 14px;
  background: #ff6a55;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.dark-mode .-\31 oVWW_head.-\31 oVWW_active:before {
  border-color: #1a1d1f;
}

.-\31 oVWW_body {
  width: 392px;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  padding: 12px 24px 24px;
  transition: all .2s;
  position: absolute;
  top: calc(100% + 18px);
  right: -92px;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: 0 40px 64px -12px rgba(0, 0, 0, .08), 0 0 14px -4px rgba(0, 0, 0, .05), 0 32px 48px -8px rgba(0, 0, 0, .1);
}

@media only screen and (max-width: 1339px) {
  .-\31 oVWW_body {
    top: calc(100% + 15px);
    right: -72px;
  }
}

@media only screen and (max-width: 767px) {
  .-\31 oVWW_body {
    width: auto;
    padding: 12px 16px 20px;
    top: 100%;
    left: 16px;
    right: 16px;
  }
}

.dark-mode .-\31 oVWW_body {
  background: #111315;
  border-color: #272b30;
}

.-\31 oVWW_body:before {
  content: "";
  width: 20px;
  height: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  position: absolute;
  bottom: 100%;
  right: 105px;
}

@media only screen and (max-width: 1339px) {
  .-\31 oVWW_body:before {
    right: 84px;
  }
}

@media only screen and (max-width: 767px) {
  .-\31 oVWW_body:before {
    right: 92px;
  }
}

.dark-mode .-\31 oVWW_body:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E");
}

.-\31 oVWW_top {
  align-items: center;
  margin: 0 -12px 12px 0;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .-\31 oVWW_top {
    margin-right: -8px;
  }
}

.-\31 oVWW_title {
  letter-spacing: -.02em;
  margin-right: auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .-\31 oVWW_title {
    font-size: 18px;
  }
}

.-\31 oVWW_actionsHead {
  background: #efefef;
}

.-\31 oVWW_actionsHead svg {
  fill: #1a1d1f;
}

.dark-mode .-\31 oVWW_actionsHead {
  background: #272b30;
}

.dark-mode .-\31 oVWW_actionsHead svg {
  fill: #fcfcfc;
}

.-\31 oVWW_list {
  margin: 0 -12px;
}

.-\31 oVWW_button {
  width: 100%;
  margin-top: 24px;
}

@media only screen and (max-width: 767px) {
  .-\31 oVWW_button {
    margin-top: 16px;
  }
}

.ij8jaq_actions {
  z-index: 3;
  display: inline-block;
  position: relative;
}

.ij8jaq_actions.ij8jaq_active {
  z-index: 10;
}

.ij8jaq_actions.ij8jaq_active .ij8jaq_head {
  background: #efefef;
}

.ij8jaq_actions.ij8jaq_active .ij8jaq_head svg {
  fill: #1a1d1f;
}

.dark-mode .ij8jaq_actions.ij8jaq_active .ij8jaq_head {
  background: #272b30;
}

.dark-mode .ij8jaq_actions.ij8jaq_active .ij8jaq_head svg {
  fill: #fcfcfc;
}

.ij8jaq_actions.ij8jaq_active .ij8jaq_body {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.ij8jaq_actions.ij8jaq_small .ij8jaq_head {
  width: 32px;
  height: 32px;
}

.ij8jaq_actions.ij8jaq_small .ij8jaq_head svg {
  width: 20px;
  height: 20px;
}

.ij8jaq_actions.ij8jaq_small .ij8jaq_body {
  right: -8px;
}

.ij8jaq_actions.ij8jaq_small .ij8jaq_body:before {
  right: 14px;
}

.ij8jaq_actions.ij8jaq_up .ij8jaq_body {
  top: auto;
  bottom: calc(100% + 12px);
  box-shadow: 0 20px 32px -6px rgba(0, 0, 0, .08), 0 0 7px -4px rgba(0, 0, 0, .05), 0 16px 24px -4px rgba(0, 0, 0, .1);
}

.ij8jaq_actions.ij8jaq_up .ij8jaq_body:before {
  top: 100%;
  bottom: auto;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ij8jaq_head {
  width: 40px;
  height: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border-radius: 50%;
  transition: background .2s;
  position: relative;
}

.ij8jaq_head svg {
  fill: #6f767e;
  transition: fill .2s;
}

.ij8jaq_head:hover svg {
  fill: #2a85ff;
}

.dark-mode .ij8jaq_head {
  background: #111315;
}

.dark-mode .ij8jaq_head:hover svg {
  fill: #2a85ff;
}

.ij8jaq_body {
  width: 264px;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  padding: 12px;
  transition: all .2s;
  position: absolute;
  top: calc(100% + 12px);
  right: -12px;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: 0 40px 64px -12px rgba(0, 0, 0, .08), 0 0 14px -4px rgba(0, 0, 0, .05), 0 32px 48px -8px rgba(0, 0, 0, .1);
}

.dark-mode .ij8jaq_body {
  background: #111315;
  border-color: #272b30;
}

.ij8jaq_body:before {
  content: "";
  width: 20px;
  height: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  position: absolute;
  bottom: 100%;
  right: 20px;
}

.dark-mode .ij8jaq_body:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E");
}

.ij8jaq_option {
  width: 100%;
  color: #6f767e;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 12px;
  align-items: center;
  padding: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  transition: all .2s;
  display: flex;
}

.ij8jaq_option svg {
  fill: #6f767e;
  margin-right: 12px;
  transition: fill .2s;
}

.ij8jaq_option:hover, .ij8jaq_option.ij8jaq_selectioned {
  color: #1a1d1f;
  background: #efefef;
}

.ij8jaq_option:hover svg, .ij8jaq_option.ij8jaq_selectioned svg {
  fill: #1a1d1f;
}

.dark-mode .ij8jaq_option:hover, .dark-mode .ij8jaq_option.ij8jaq_selectioned {
  color: #fcfcfc;
  background: none;
}

.dark-mode .ij8jaq_option:hover svg, .dark-mode .ij8jaq_option.ij8jaq_selectioned svg {
  fill: #fcfcfc;
}

.oL4Zja_item {
  border-radius: 8px;
  align-items: center;
  padding: 12px;
  transition: background .2s;
  display: flex;
  position: relative;
}

.oL4Zja_item:hover {
  background: #f4f4f4;
}

.oL4Zja_item:hover .oL4Zja_icon {
  border-color: #f4f4f4;
}

.dark-mode .oL4Zja_item:hover {
  background: #33383f;
}

.dark-mode .oL4Zja_item:hover .oL4Zja_icon {
  border-color: #33383f;
}

@media only screen and (max-width: 767px) {
  .oL4Zja_item:hover {
    background: none;
  }
}

.oL4Zja_item:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #9a9fa5;
  border-radius: 50%;
  position: absolute;
  top: 18px;
  right: 12px;
}

.oL4Zja_item.oL4Zja_new:after {
  background: #2a85ff;
}

.oL4Zja_item:not(:last-child):before {
  content: "";
  height: 1px;
  background: #efefef;
  position: absolute;
  bottom: 0;
  left: 12px;
  right: 12px;
}

.dark-mode .oL4Zja_item:not(:last-child):before {
  background: #272b30;
}

@media only screen and (max-width: 767px) {
  .oL4Zja_item:nth-child(n+3) {
    display: none;
  }
}

@media only screen and (max-width: 639px) {
  .oL4Zja_item:nth-child(n+3) {
    display: flex;
  }

  .oL4Zja_item:nth-child(n+6) {
    display: none;
  }
}

.oL4Zja_avatar {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  position: relative;
}

.oL4Zja_avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.oL4Zja_icon {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: border-color .2s;
  display: flex;
  position: absolute;
  bottom: -2px;
  right: -2px;
}

.dark-mode .oL4Zja_icon {
  border-color: #111315;
}

.oL4Zja_details {
  width: calc(100% - 48px);
  flex: 0 0 calc(100% - 48px);
  padding-left: 12px;
}

.oL4Zja_line {
  align-items: center;
  padding-right: 24px;
  display: flex;
}

.oL4Zja_subtitle {
  color: #1a1d1f;
  margin-right: 4px;
  font-weight: 700;
}

.dark-mode .oL4Zja_subtitle {
  color: #fcfcfc;
}

.oL4Zja_login {
  color: #9a9fa5;
  margin-right: auto;
  font-weight: 500;
}

.oL4Zja_time {
  color: #9a9fa5;
  margin-left: 16px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23077;
}

.oL4Zja_content {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #6f767e;
  font-weight: 500;
  overflow: hidden;
}

.oL4Zja_content strong {
  color: #1a1d1f;
  font-weight: 700;
}

.dark-mode .oL4Zja_content strong {
  color: #fcfcfc;
}

.MSxZ6a_user {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .MSxZ6a_user {
    position: static;
  }
}

.MSxZ6a_user.MSxZ6a_active .MSxZ6a_body {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.MSxZ6a_head {
  width: 48px;
  height: 48px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
}

.MSxZ6a_head img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.MSxZ6a_body {
  width: 280px;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  padding: 16px;
  transition: all .2s;
  position: absolute;
  top: calc(100% + 18px);
  right: -20px;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: 0 40px 64px -12px rgba(0, 0, 0, .08), 0 0 14px -4px rgba(0, 0, 0, .05), 0 32px 48px -8px rgba(0, 0, 0, .1);
}

@media only screen and (max-width: 1339px) {
  .MSxZ6a_body {
    border-radius: 12px;
    top: calc(100% + 15px);
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .MSxZ6a_body {
    width: auto;
    padding: 12px 16px 20px;
    top: 100%;
    left: 16px;
    right: 16px;
  }
}

.dark-mode .MSxZ6a_body {
  background: #111315;
  border-color: #272b30;
}

.MSxZ6a_body:before {
  content: "";
  width: 20px;
  height: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  position: absolute;
  bottom: 100%;
  right: 33px;
}

@media only screen and (max-width: 1339px) {
  .MSxZ6a_body:before {
    right: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .MSxZ6a_body:before {
    right: 21px;
  }
}

.dark-mode .MSxZ6a_body:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E");
}

.MSxZ6a_menu:not(:last-child) {
  border-bottom: 1px solid #efefef;
  margin-bottom: 12px;
  padding-bottom: 12px;
}

@media only screen and (max-width: 767px) {
  .MSxZ6a_menu:not(:last-child) {
    margin-bottom: 8px;
    padding-bottom: 8px;
  }
}

.dark-mode .MSxZ6a_menu:not(:last-child) {
  border-color: #272b30;
}

.MSxZ6a_item {
  width: 100%;
  height: 48px;
  color: #6f767e;
  border-radius: 12px;
  align-items: center;
  padding: 0 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  transition: all .2s;
  display: flex;
}

.MSxZ6a_item svg {
  fill: #6f767e;
  margin-right: 12px;
  transition: fill .2s;
}

.MSxZ6a_item:hover, .MSxZ6a_item.MSxZ6a_active {
  color: #1a1d1f;
}

.MSxZ6a_item:hover svg, .MSxZ6a_item.MSxZ6a_active svg {
  fill: #1a1d1f;
}

.dark-mode .MSxZ6a_item:hover, .dark-mode .MSxZ6a_item.MSxZ6a_active {
  color: #fcfcfc;
}

.dark-mode .MSxZ6a_item:hover svg, .dark-mode .MSxZ6a_item.MSxZ6a_active svg {
  fill: #fcfcfc;
}

.MSxZ6a_item.MSxZ6a_active {
  background: #f4f4f4;
}

.dark-mode .MSxZ6a_item.MSxZ6a_active {
  background: none;
}

.MSxZ6a_item.MSxZ6a_color {
  color: #8e59ff;
}

.MSxZ6a_item.MSxZ6a_color svg {
  fill: #8e59ff;
}

.dark-mode .MSxZ6a_item.MSxZ6a_color {
  color: #8e59ff;
}

.dark-mode .MSxZ6a_item.MSxZ6a_color svg {
  fill: #8e59ff;
}

.MSxZ6a_item.MSxZ6a_color:hover {
  opacity: .8;
}

._3A2Duq_row {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  ._3A2Duq_row {
    display: block;
  }
}

._3A2Duq_col:first-child {
  width: calc(100% - 340px);
  flex: 0 0 calc(100% - 340px);
  padding-right: 8px;
}

@media only screen and (max-width: 1339px) {
  ._3A2Duq_col:first-child {
    width: calc(100% - 324px);
    flex: 0 0 calc(100% - 324px);
  }
}

@media only screen and (max-width: 1259px) {
  ._3A2Duq_col:first-child {
    width: calc(100% - 312px);
    flex: 0 0 calc(100% - 312px);
  }
}

@media only screen and (max-width: 1023px) {
  ._3A2Duq_col:first-child {
    width: 100%;
    margin-bottom: 8px;
    padding-right: 0;
  }
}

._3A2Duq_col:nth-child(2) {
  width: 340px;
  flex-shrink: 0;
}

@media only screen and (max-width: 1339px) {
  ._3A2Duq_col:nth-child(2) {
    width: 324px;
  }
}

@media only screen and (max-width: 1259px) {
  ._3A2Duq_col:nth-child(2) {
    width: 312px;
  }
}

@media only screen and (max-width: 1023px) {
  ._3A2Duq_col:nth-child(2) {
    width: 100%;
  }
}

._3A2Duq_card:not(:last-child) {
  margin-bottom: 8px;
}

.RcBTUW_nav {
  background: #f4f4f4;
  border-radius: 20px;
  margin-bottom: 32px;
  padding: 8px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .RcBTUW_nav {
    margin-bottom: 24px;
  }
}

.dark-mode .RcBTUW_nav {
  background: rgba(17, 19, 21, .5);
}

.RcBTUW_item.RcBTUW_active {
  background: #fcfcfc;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, .25), inset 0 -1px 1px rgba(0, 0, 0, .04), inset 0 2px rgba(255, 255, 255, .25);
}

.dark-mode .RcBTUW_item.RcBTUW_active {
  background: #272b30;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, .25), inset 0 -1px 1px rgba(0, 0, 0, .04), inset 0 2px rgba(255, 255, 255, .06);
}

.KvvVrq_item {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 12px;
  flex: 0 0 50%;
  padding: 20px;
  transition: all .2s;
  display: flex;
}

@media only screen and (max-width: 1419px) {
  .KvvVrq_item {
    padding: 20px 16px;
  }
}

@media only screen and (max-width: 767px) {
  .KvvVrq_item {
    display: block;
  }
}

.KvvVrq_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .KvvVrq_icon {
    display: none;
  }
}

.KvvVrq_subtitle {
  color: #6f767e;
  align-items: center;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23077;
  display: flex;
}

.KvvVrq_tooltip svg {
  fill: #9a9fa5;
}

.KvvVrq_counter {
  letter-spacing: -.03em;
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
}

@media only screen and (max-width: 1419px) {
  .KvvVrq_counter {
    font-size: 40px;
    line-height: 48px;
  }
}

.KvvVrq_balance {
  margin: -12px -12px 0 auto;
}

@media only screen and (max-width: 767px) {
  .KvvVrq_balance {
    margin: 4px 0 0;
  }
}

.dark-mode .KvvVrq_balance {
  background: #272b30 !important;
}

.Tz1Tgq_tooltip {
  cursor: pointer;
  margin-left: 4px;
  display: inline-block;
}

.Tz1Tgq_tooltip .Tz1Tgq_tooltipWpapper {
  border-radius: 4px;
  padding: 3px 12px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), inset 0 0 1px #000;
}

.GrPhIq_negative, .GrPhIq_positive {
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33333;
  display: inline-flex;
}

.GrPhIq_negative svg, .GrPhIq_positive svg {
  margin-right: 4px;
}

.GrPhIq_negative {
  color: #ff6a55;
}

.GrPhIq_negative svg {
  fill: #ff6a55;
}

.GrPhIq_positive {
  color: #83bf6e;
}

.GrPhIq_positive svg {
  fill: #83bf6e;
}

.GrPhIq_background.GrPhIq_positive, .GrPhIq_background.GrPhIq_negative {
  height: 24px;
  border-radius: 8px;
  padding: 0 4px;
}

.GrPhIq_background.GrPhIq_positive {
  background: #eafae5;
}

.dark-mode .GrPhIq_background.GrPhIq_positive {
  background: rgba(234, 250, 229, .25);
}

.GrPhIq_background.GrPhIq_negative {
  background: #ffe7e4;
}

.dark-mode .GrPhIq_background.GrPhIq_negative {
  background: rgba(255, 216, 211, .15);
}

.G91BYW_card {
  background: #fcfcfc;
  border-radius: 8px;
  padding: 24px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .G91BYW_card {
    padding: 16px;
  }
}

.dark-mode .G91BYW_card {
  background: #1a1d1f;
}

.G91BYW_head {
  min-height: 40px;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .G91BYW_head {
    margin-bottom: 24px;
  }
}

.G91BYW_title {
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .G91BYW_title {
    line-height: 40px;
  }
}

._0mykYa_label {
  color: #33383f;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
}

.dark-mode ._0mykYa_label {
  color: #efefef;
}

._0mykYa_tooltip {
  position: relative;
  top: -1px;
}

._0mykYa_tooltip svg {
  fill: #9a9fa5;
}

._0mykYa_dropdown {
  z-index: 3;
  position: relative;
}

._0mykYa_dropdown._0mykYa_active {
  z-index: 10;
}

._0mykYa_dropdown._0mykYa_active ._0mykYa_head {
  background: #fff;
  box-shadow: inset 0 0 0 2px #9a9fa5;
}

.dark-mode ._0mykYa_dropdown._0mykYa_active ._0mykYa_head {
  color: #fcfcfc;
  background: #111315;
  box-shadow: inset 0 0 0 2px #33383f;
}

._0mykYa_dropdown._0mykYa_active ._0mykYa_head:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

._0mykYa_dropdown._0mykYa_active ._0mykYa_body {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

._0mykYa_dropdown._0mykYa_small ._0mykYa_head {
  height: 40px;
  padding: 0 40px 0 16px;
  line-height: 40px;
}

._0mykYa_dropdown._0mykYa_small ._0mykYa_head:before {
  width: 40px;
  background-size: 16px;
}

._0mykYa_dropdown._0mykYa_small ._0mykYa_option {
  padding: 4px 16px;
}

._0mykYa_head {
  height: 48px;
  color: #1a1d1f;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 12px;
  padding: 0 48px 0 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 48px;
  transition: all .2s;
  position: relative;
  box-shadow: inset 0 0 0 2px #efefef;
}

._0mykYa_head:before {
  content: "";
  width: 48px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") 50% / 24px no-repeat;
  transition: -webkit-transform .2s, transform .2s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

._0mykYa_head:hover {
  box-shadow: inset 0 0 0 2px #9a9fa5;
}

.dark-mode ._0mykYa_head {
  color: #6f767e;
  background: none;
  box-shadow: inset 0 0 0 2px #272b30;
}

.dark-mode ._0mykYa_head:hover {
  box-shadow: inset 0 0 0 2px #33383f;
}

._0mykYa_selection {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._0mykYa_body {
  visibility: hidden;
  opacity: 0;
  background: #fff;
  border-radius: 12px;
  padding: 8px 0;
  transition: all .2s;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: inset 0 0 0 2px #efefef, 0 4px 12px rgba(244, 244, 244, .1);
}

.dark-mode ._0mykYa_body {
  background: #111315;
  box-shadow: inset 0 0 0 2px #33383f, 0 4px 12px rgba(17, 19, 21, .1);
}

._0mykYa_body._0mykYa_bodyUp {
  top: auto;
  bottom: calc(100% + 2px);
}

._0mykYa_option {
  color: #6f767e;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  transition: color .2s;
}

._0mykYa_option:hover {
  color: #1a1d1f;
}

.dark-mode ._0mykYa_option:hover {
  color: #fcfcfc;
}

._0mykYa_option._0mykYa_selectioned, .dark-mode ._0mykYa_option._0mykYa_selectioned {
  color: #2a85ff;
}

.ZQovLW_info {
  color: #6f767e;
  margin-bottom: 24px;
  font-weight: 500;
}

.ZQovLW_list {
  flex-wrap: wrap;
  margin: -32px -16px 0;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .ZQovLW_list {
    margin: 0;
    display: block;
  }
}

.ZQovLW_item {
  width: calc(50% - 32px);
  color: #1a1d1f;
  cursor: pointer;
  flex: 0 0 calc(50% - 32px);
  align-items: center;
  margin: 32px 16px 0;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .ZQovLW_item {
    width: 100%;
    margin: 0;
  }
}

.dark-mode .ZQovLW_item {
  color: #fcfcfc;
}

.ZQovLW_item:hover .ZQovLW_icon {
  background: #efefef;
}

.dark-mode .ZQovLW_item:hover .ZQovLW_icon {
  background: #272b30;
}

@media only screen and (max-width: 767px) {
  .ZQovLW_item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.ZQovLW_icon {
  width: 64px;
  height: 64px;
  border: 2px solid #efefef;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: background .2s;
  display: flex;
}

.ZQovLW_icon svg {
  fill: #272b30;
}

.dark-mode .ZQovLW_icon {
  border-color: #272b30;
}

.dark-mode .ZQovLW_icon svg {
  fill: #fcfcfc;
}

.ZQovLW_title {
  margin-bottom: 8px;
}

.ZQovLW_line {
  align-items: center;
  display: flex;
}

.ZQovLW_status {
  color: #1a1d1f;
  margin-right: 4px;
}

.ZQovLW_user {
  border: 1px solid #efefef;
  border-radius: 4px;
  align-items: center;
  padding: 1px 8px 1px 1px;
  display: flex;
}

.dark-mode .ZQovLW_user {
  border-color: #272b30;
}

.ZQovLW_avatar {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 4px;
  font-size: 0;
}

.ZQovLW_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.ZQovLW_action {
  color: #6f767e;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33333;
}

.dTdlsa_modal {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
  background: #1a1d1f;
  padding: 112px 40px 56px;
  -webkit-animation: dTdlsa_showModal .4s forwards;
  animation: dTdlsa_showModal .4s forwards;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@media only screen and (max-width: 1023px) {
  .dTdlsa_modal {
    padding: 112px 24px 56px;
  }
}

@media only screen and (max-width: 767px) {
  .dTdlsa_modal {
    padding: 148px 16px 32px;
  }
}

.dark-mode .dTdlsa_modal {
  background: #fcfcfc;
}

@-webkit-keyframes dTdlsa_showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dTdlsa_showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dTdlsa_outer {
  max-width: 1124px;
  width: 100%;
  margin: auto;
}

.dTdlsa_video video {
  width: 100%;
  border-radius: 12px;
}

.dTdlsa_wrapper {
  padding: 0 56px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .dTdlsa_wrapper {
    padding: 0;
  }
}

.dTdlsa_preview {
  height: 100%;
  align-items: center;
  display: flex;
}

.dTdlsa_preview img {
  width: 100%;
  border-radius: 16px;
}

.dTdlsa_figcaption {
  text-align: center;
  color: #6f767e;
  margin-top: 32px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23077;
}

@media only screen and (max-width: 1259px) {
  .dTdlsa_figcaption {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .dTdlsa_figcaption {
    margin-top: 16px;
  }
}

.VAuqPq_panel {
  align-items: center;
  display: flex;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
}

@media only screen and (max-width: 1023px) {
  .VAuqPq_panel {
    top: 24px;
    left: 24px;
    right: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .VAuqPq_panel {
    top: 24px;
    left: 16px;
    right: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .VAuqPq_panel.VAuqPq_panelGallery {
    flex-wrap: wrap;
  }

  .VAuqPq_panel.VAuqPq_panelGallery .VAuqPq_title {
    max-width: calc(100% - 96px);
    text-align: left;
    flex-grow: 1;
    order: 1;
    padding-right: 24px;
    position: static;
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  .VAuqPq_panel.VAuqPq_panelGallery .VAuqPq_control {
    order: 2;
    align-self: flex-start;
  }

  .VAuqPq_panel.VAuqPq_panelGallery .VAuqPq_actions {
    width: 100%;
    order: 3;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .VAuqPq_panel.VAuqPq_panelGallery .VAuqPq_title {
    padding: 0 16px 0 0;
  }
}

.VAuqPq_actions {
  flex-shrink: 0;
  align-items: center;
  margin-right: auto;
  display: flex;
}

.VAuqPq_actions .VAuqPq_button {
  margin-left: 12px;
}

.VAuqPq_favorite {
  height: 40px;
  color: #1a1d1f;
  background: #efefef;
  border: 1px solid #fcfcfc;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.84615;
  transition: all .2s;
}

@media only screen and (max-width: 767px) {
  .VAuqPq_favorite {
    width: 40px;
    border-radius: 50%;
    padding: 0;
    font-size: 0;
  }
}

.dark-mode .VAuqPq_favorite {
  background: none;
  border-color: #efefef;
}

.VAuqPq_favorite svg {
  fill: #ff6a55;
  margin-right: 10px;
  transition: fill .2s;
}

@media only screen and (max-width: 767px) {
  .VAuqPq_favorite svg {
    margin-right: 0;
  }
}

.VAuqPq_favorite:hover {
  color: #fcfcfc;
  background: #ff6a55;
  border-color: #ff6a55;
}

.VAuqPq_favorite:hover svg {
  fill: #fcfcfc;
}

.VAuqPq_title {
  max-width: 550px;
  text-align: center;
  flex-wrap: 600;
  color: #fcfcfc;
  font-size: 20px;
  line-height: 1.2;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1259px) {
  .VAuqPq_title {
    max-width: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .VAuqPq_title {
    max-width: 100%;
    flex-grow: 1;
    padding: 0 16px;
    font-size: 15px;
    position: static;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.dark-mode .VAuqPq_title {
  color: #1a1d1f;
}

.VAuqPq_control {
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.VAuqPq_close {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  font-size: 0;
}

.VAuqPq_close svg {
  fill: #33383f;
  transition: -webkit-transform .2s, transform .2s;
}

.dark-mode .VAuqPq_close {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .06);
}

.VAuqPq_close:hover svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.VAuqPq_link {
  width: 40px;
  height: 40px;
  border: 2px solid #272b30;
  border-radius: 50%;
  margin-right: 16px;
  font-size: 0;
  transition: background .2s;
}

.VAuqPq_link svg {
  fill: #6f767e;
  transition: fill .2s;
}

.VAuqPq_link:hover {
  background: #272b30;
}

.VAuqPq_link:hover svg {
  fill: #fcfcfc;
}

.dark-mode .VAuqPq_link {
  border-color: #efefef;
}

.dark-mode .VAuqPq_link:hover {
  background: #efefef;
}

.dark-mode .VAuqPq_link:hover svg {
  fill: #1a1d1f;
}

.rEGg5q_info {
  color: #6f767e;
  margin-bottom: 16px;
  font-weight: 500;
}

.rEGg5q_btns {
  margin: 0 -8px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .rEGg5q_btns {
    margin: 0 -6px;
  }
}

.rEGg5q_button {
  width: calc(33.333% - 16px);
  flex: 0 0 calc(33.333% - 16px);
  margin: 0 8px;
}

@media only screen and (max-width: 767px) {
  .rEGg5q_button {
    width: calc(33.333% - 12px);
    flex: 0 0 calc(33.333% - 12px);
    margin: 0 6px;
    font-size: 0;
  }

  .rEGg5q_button svg {
    margin: 0 !important;
  }
}

.pvPKGW_chart {
  width: auto;
  height: 274px;
  margin-bottom: -10px;
  margin-left: -35px;
}

.Rg-BWq_background {
  height: 400px;
  margin: -40px -40px 0;
}

@media only screen and (max-width: 1339px) {
  .Rg-BWq_background {
    margin: -32px -24px 0;
  }
}

@media only screen and (max-width: 1259px) {
  .Rg-BWq_background {
    height: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_background {
    height: 252px;
    margin: -24px -16px 0;
  }
}

.Rg-BWq_background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Rg-BWq_card {
  max-width: 1200px;
  margin: -64px auto 0;
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_card {
    margin-top: -188px;
  }
}

.Rg-BWq_control {
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_control {
    flex-wrap: wrap;
  }
}

.Rg-BWq_nav {
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_nav {
    width: 100%;
    margin: 0 0 16px;
  }
}

.Rg-BWq_link {
  color: #6f767e;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  transition: all .2s;
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_link {
    flex-grow: 1;
  }
}

.Rg-BWq_link:hover, .Rg-BWq_link.Rg-BWq_active {
  color: #1a1d1f;
}

.dark-mode .Rg-BWq_link:hover, .dark-mode .Rg-BWq_link.Rg-BWq_active {
  color: #fcfcfc;
}

.Rg-BWq_link.Rg-BWq_active {
  background: #efefef;
}

.dark-mode .Rg-BWq_link.Rg-BWq_active {
  background: #272b30;
}

.Rg-BWq_link:not(:last-child) {
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_link:not(:last-child) {
    margin-right: 0;
  }

  .Rg-BWq_dropdownBox {
    flex-grow: 1;
  }
}

.Rg-BWq_filters {
  margin-left: 16px;
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_filters {
    margin-left: 12px;
  }
}

.Rg-BWq_products {
  flex-wrap: wrap;
  margin: -32px -12px 0;
  display: flex;
}

@media only screen and (max-width: 639px) {
  .Rg-BWq_products {
    margin: 0;
    display: block;
  }
}

.Rg-BWq_product {
  width: calc(33.333% - 24px);
  flex: 0 0 calc(33.333% - 24px);
  margin: 32px 12px 0;
}

@media only screen and (max-width: 1023px) {
  .Rg-BWq_product {
    width: calc(50% - 24px);
    flex: 0 0 calc(50% - 24px);
  }
}

@media only screen and (max-width: 639px) {
  .Rg-BWq_product {
    width: 100%;
    margin: 24px 0 0;
  }
}

.Rg-BWq_foot {
  text-align: center;
  margin-top: 40px;
}

@media only screen and (max-width: 1339px) {
  .Rg-BWq_foot {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .Rg-BWq_foot {
    margin-top: 24px;
  }
}

.DxzeEq_profile {
  border-bottom: 1px solid #efefef;
  align-items: flex-start;
  margin-bottom: 40px;
  padding-bottom: 40px;
  display: flex;
}

@media only screen and (max-width: 1259px) {
  .DxzeEq_profile {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: 1023px) {
  .DxzeEq_profile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .DxzeEq_profile {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

.dark-mode .DxzeEq_profile {
  border-color: #272b30;
}

.DxzeEq_details {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .DxzeEq_details {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .DxzeEq_details {
    margin-bottom: 12px;
  }
}

.DxzeEq_avatar {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 16px;
  position: relative;
}

.DxzeEq_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.DxzeEq_add {
  width: 24px;
  height: 24px;
  background: #2a85ff;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 0;
  transition: background .2s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.DxzeEq_add svg {
  fill: #fff;
}

.DxzeEq_add:hover {
  background: #1176ff;
}

@media only screen and (max-width: 767px) {
  .DxzeEq_man {
    font-size: 20px;
  }
}

.DxzeEq_info {
  letter-spacing: -.02em;
  color: #6f767e;
  margin-top: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .DxzeEq_info {
    margin-top: 4px;
    font-size: 15px;
  }
}

.DxzeEq_contacts {
  flex-shrink: 0;
  align-items: center;
  margin-left: 40px;
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .DxzeEq_contacts {
    margin-left: 0;
  }
}

.DxzeEq_socials {
  align-items: center;
  display: flex;
}

.DxzeEq_social svg {
  fill: #6f767e;
  transition: fill .2s;
}

.DxzeEq_social:hover svg {
  fill: #2a85ff;
}

.DxzeEq_social:not(:last-child) {
  margin-right: 32px;
}

.DxzeEq_button {
  margin-left: 32px;
}

@media only screen and (max-width: 1023px) {
  .DxzeEq_button {
    margin-left: auto;
  }
}

@media only screen and (max-width: 767px) {
  .DxzeEq_button {
    min-width: 132px;
  }
}

.__wP6a_form {
  width: 100%;
  margin-bottom: 24px;
}

.__wP6a_item:not(:last-child) {
  margin-bottom: 24px;
}

.__wP6a_label {
  color: #6f767e;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
}

.dark-mode .__wP6a_dropdownHead {
  color: #fcfcfc;
}

.__wP6a_checkbox:not(:last-child) {
  margin-bottom: 12px;
}

.__wP6a_box {
  position: relative;
}

.__wP6a_box .__wP6a_dropdownHead {
  padding-left: 48px;
}

.__wP6a_box svg {
  z-index: 10;
  pointer-events: none;
  fill: #ffd88d;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.__wP6a_btns {
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
}

.__wP6a_btns .__wP6a_button:not(:last-child) {
  margin-right: 12px;
}

.egaYUa_form {
  width: 360px;
  position: relative;
}

.egaYUa_input {
  width: 100%;
  height: 40px;
  color: #1a1d1f;
  background: #f4f4f4;
  border-radius: 12px;
  padding: 0 20px 0 44px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
}

.dark-mode .egaYUa_input {
  color: #fcfcfc;
  background: #272b30;
}

.egaYUa_input::-webkit-input-placeholder {
  color: #9a9fa5;
}

.egaYUa_input::placeholder {
  color: #9a9fa5;
}

.egaYUa_result {
  width: 44px;
  padding-right: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.egaYUa_result svg {
  fill: #6f767e;
  transition: fill .2s;
}

.egaYUa_result:hover svg {
  fill: #2a85ff;
}

._2n1iya_checkbox {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  position: relative;
}

._2n1iya_checkbox._2n1iya_reverse ._2n1iya_inner {
  flex-direction: row-reverse;
}

._2n1iya_checkbox._2n1iya_reverse ._2n1iya_text {
  padding: 0 16px 0 0;
}

._2n1iya_input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

._2n1iya_input:checked + ._2n1iya_inner ._2n1iya_tick {
  background: #2a85ff;
  border-color: #2a85ff;
}

._2n1iya_input:checked + ._2n1iya_inner ._2n1iya_tick:before {
  opacity: 1;
}

._2n1iya_inner {
  display: flex;
}

._2n1iya_inner:hover ._2n1iya_tick {
  border-color: #2a85ff;
}

._2n1iya_tick {
  width: 24px;
  height: 24px;
  border: 2px solid rgba(111, 118, 126, .4);
  border-radius: 6px;
  flex-shrink: 0;
  transition: all .2s;
  position: relative;
}

._2n1iya_tick:before {
  content: "";
  width: 17px;
  height: 12px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  transition: opacity .2s;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

._2n1iya_text {
  color: #1a1d1f;
  flex-grow: 1;
  padding-left: 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.71429;
}

.dark-mode ._2n1iya_text {
  color: #fcfcfc;
}

._51mW7W_filters {
  position: relative;
}

._51mW7W_filters._51mW7W_active ._51mW7W_body, ._51mW7W_filters._51mW7W_active ._51mW7W_overlay {
  visibility: visible;
  opacity: 1;
}

._51mW7W_body {
  z-index: 20;
  width: 408px;
  visibility: hidden;
  opacity: 0;
  background: #fcfcfc;
  border-radius: 16px;
  padding: 24px;
  transition: all .3s;
  position: absolute;
  top: -24px;
  right: -24px;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, .05), 0 32px 48px -8px rgba(0, 0, 0, .1);
}

@media only screen and (max-width: 767px) {
  ._51mW7W_body {
    width: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }
}

.dark-mode ._51mW7W_body {
  background: #1a1d1f;
}

._51mW7W_top {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

._51mW7W_title {
  margin-right: auto;
}

._51mW7W_close {
  width: 36px;
  height: 36px;
  background: #efefef;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 24px;
}

._51mW7W_close svg {
  fill: #1a1d1f;
  transition: -webkit-transform .2s, transform .2s;
}

.dark-mode ._51mW7W_close {
  background: #272b30;
}

.dark-mode ._51mW7W_close svg {
  fill: #fcfcfc;
}

._51mW7W_close:hover svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

._51mW7W_overlay {
  z-index: 18;
  visibility: hidden;
  opacity: 0;
  background: rgba(244, 244, 244, .8);
  transition: all .2s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dark-mode ._51mW7W_overlay {
  background: rgba(39, 43, 48, .9);
}

.Ec8U7W_product:hover .Ec8U7W_preview:after, .Ec8U7W_product:hover .Ec8U7W_control, .Ec8U7W_product:hover .Ec8U7W_checkbox, .Ec8U7W_product.Ec8U7W_active .Ec8U7W_checkbox {
  visibility: visible;
  opacity: 1;
}

.Ec8U7W_product.Ec8U7W_active .Ec8U7W_preview:before {
  border-color: #2a85ff;
}

.Ec8U7W_preview:after, .Ec8U7W_control, .Ec8U7W_checkbox {
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
}

.Ec8U7W_preview {
  height: 200px;
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

.Ec8U7W_preview:after, .Ec8U7W_preview:before {
  content: "";
  width: 100%;
  height: 100%;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.Ec8U7W_preview:after {
  background: rgba(17, 19, 21, .8);
}

.Ec8U7W_preview:before {
  z-index: 2;
  pointer-events: none;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 12px;
}

.Ec8U7W_preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.Ec8U7W_preview .Ec8U7W_checkbox {
  z-index: 3;
  position: absolute;
  top: 12px;
  left: 12px;
}

@media only screen and (max-width: 1023px) {
  .Ec8U7W_preview .Ec8U7W_checkbox {
    display: none;
  }
}

.Ec8U7W_preview .Ec8U7W_checkbox .Ec8U7W_checkboxTick {
  border-color: #2a85ff;
}

.Ec8U7W_control {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Ec8U7W_line {
  align-items: flex-start;
  display: flex;
}

.Ec8U7W_title {
  margin-right: auto;
}

.Ec8U7W_price, .Ec8U7W_empty {
  border-radius: 6px;
  flex-shrink: 0;
  margin-left: 32px;
  padding: 0 8px;
  font-weight: 700;
  line-height: 32px;
  display: inline-block;
}

.Ec8U7W_price {
  background: #b5e4ca;
}

.dark-mode .Ec8U7W_price {
  color: #1a1d1f;
}

.Ec8U7W_empty {
  background: #efefef;
}

.dark-mode .Ec8U7W_empty {
  color: #fcfcfc;
  background: #272b30;
}

.Ec8U7W_date {
  color: #6f767e;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Ec8U7W_date {
    margin-top: 2px;
  }
}

.Ec8U7W_date svg {
  fill: #6f767e;
  margin-right: 8px;
}

.Ec8U7W_rating {
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Ec8U7W_rating {
    margin-top: 4px;
  }
}

.Ec8U7W_rating svg {
  fill: #ffc554;
  margin-right: 8px;
}

.Ec8U7W_rating span {
  color: #6f767e;
  margin-left: 4px;
}

.Ec8U7W_ratingEmpty {
  color: #6f767e;
}

.Ec8U7W_ratingEmpty svg {
  fill: #6f767e;
}

.b6SJPa_control {
  align-items: center;
  display: flex;
}

.b6SJPa_button {
  width: 36px;
  height: 36px;
  background: #fcfcfc;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.b6SJPa_button svg {
  fill: #6f767e;
  transition: fill .2s;
}

.b6SJPa_button:hover svg {
  fill: #2a85ff;
}

.b6SJPa_button:not(:last-child) {
  margin-right: 16px;
}

.NWVOYq_modal {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
  background: #f4f4f4;
  padding: 120px 24px 24px;
  -webkit-animation: NWVOYq_showModal .4s forwards;
  animation: NWVOYq_showModal .4s forwards;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .NWVOYq_modal {
    padding: 88px 16px 24px;
  }
}

.dark-mode .NWVOYq_modal {
  background: #111315;
}

@-webkit-keyframes NWVOYq_showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes NWVOYq_showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.NWVOYq_outer {
  max-width: 100%;
  z-index: 2;
  border-radius: 8px;
  margin: auto;
  display: inline-block;
}

.NWVOYq_control {
  align-items: center;
  display: flex;
  position: absolute;
  top: 24px;
  left: 24px;
  right: 24px;
}

@media only screen and (max-width: 767px) {
  .NWVOYq_control {
    top: 16px;
    left: 16px;
    right: 16px;
  }
}

.dark-mode .NWVOYq_control .NWVOYq_button {
  color: #fcfcfc;
  background: #1a1d1f;
  box-shadow: inset 0 0 0 2px #272b30;
}

.dark-mode .NWVOYq_control .NWVOYq_button:hover {
  background: #272b30;
}

.NWVOYq_close {
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 50%;
  margin-left: auto;
  font-size: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .06);
}

.NWVOYq_close svg {
  fill: #33383f;
  transition: -webkit-transform .2s, transform .2s;
}

.dark-mode .NWVOYq_close {
  background: #1a1d1f;
}

.dark-mode .NWVOYq_close svg {
  fill: #fcfcfc;
}

.NWVOYq_close:hover svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

._3ixlNW_product {
  max-width: 1020px;
  background: #fcfcfc;
  border-radius: 8px;
  position: relative;
}

@media only screen and (max-width: 1259px) {
  ._3ixlNW_product {
    max-width: calc(100% - 88px);
  }
}

@media only screen and (max-width: 767px) {
  ._3ixlNW_product {
    max-width: 100%;
  }
}

.dark-mode ._3ixlNW_product {
  background: #1a1d1f;
}

._3ixlNW_product._3ixlNW_active {
  max-width: 1440px;
  display: flex;
}

@media only screen and (max-width: 1259px) {
  ._3ixlNW_product._3ixlNW_active {
    max-width: 100%;
    margin-right: 0;
  }
}

._3ixlNW_product._3ixlNW_active ._3ixlNW_details {
  width: calc(100% - 472px);
  flex: 0 0 calc(100% - 472px);
}

@media only screen and (max-width: 1259px) {
  ._3ixlNW_product._3ixlNW_active ._3ixlNW_details {
    display: none;
  }
}

._3ixlNW_product._3ixlNW_active ._3ixlNW_comments {
  display: block;
}

._3ixlNW_product._3ixlNW_active ._3ixlNW_panel, ._3ixlNW_product._3ixlNW_active ._3ixlNW_next {
  display: none;
}

._3ixlNW_comments {
  width: 472px;
  flex-shrink: 0;
  display: none;
}

@media only screen and (max-width: 1259px) {
  ._3ixlNW_comments {
    width: 600px;
  }
}

@media only screen and (max-width: 1023px) {
  ._3ixlNW_comments {
    width: 100%;
  }
}

._3ixlNW_next {
  width: 64px;
  height: 64px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 256px;
  left: calc(100% + 24px);
}

@media only screen and (max-width: 1259px) {
  ._3ixlNW_next {
    bottom: 0;
    left: auto;
    right: -88px;
  }
}

@media only screen and (max-width: 767px) {
  ._3ixlNW_next {
    display: none;
  }
}

.dark-mode ._3ixlNW_next {
  background: #272b30;
}

._3ixlNW_next svg {
  fill: #6f767e;
  transition: fill .2s;
}

._3ixlNW_next:hover svg {
  fill: #2a85ff;
}

.A8Kl6q_details {
  flex-grow: 1;
  padding: 24px;
}

@media only screen and (max-width: 767px) {
  .A8Kl6q_details {
    padding: 16px;
    overflow: hidden;
  }
}

.A8Kl6q_head {
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .A8Kl6q_head {
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
}

.A8Kl6q_nav {
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .A8Kl6q_nav {
    width: 100%;
    margin: 0 0 16px;
  }
}

.A8Kl6q_link {
  color: #6f767e;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  transition: all .2s;
}

@media only screen and (max-width: 767px) {
  .A8Kl6q_link {
    flex: 1;
  }
}

.A8Kl6q_link:hover, .A8Kl6q_link.A8Kl6q_active {
  color: #1a1d1f;
}

.dark-mode .A8Kl6q_link:hover, .dark-mode .A8Kl6q_link.A8Kl6q_active {
  color: #fcfcfc;
}

.A8Kl6q_link.A8Kl6q_active {
  background: #efefef;
}

.dark-mode .A8Kl6q_link.A8Kl6q_active {
  background: #272b30;
}

.A8Kl6q_link:not(:last-child) {
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .A8Kl6q_link:not(:last-child) {
    margin-right: 0;
  }
}

.A8Kl6q_btns {
  align-items: center;
  display: flex;
}

.A8Kl6q_favorite {
  margin-right: 16px;
}

.A8Kl6q_favorite svg {
  fill: #ff6a55;
}

.A8Kl6q_favorite:hover {
  color: #fcfcfc;
  background: #ff6a55;
  box-shadow: inset 0 0 0 1px #ff6a55;
}

.dark-mode .A8Kl6q_favorite:hover {
  box-shadow: inset 0 0 0 1px #ff6a55;
}

.A8Kl6q_favorite:hover svg {
  fill: #fcfcfc;
}

@media only screen and (max-width: 1023px) {
  .A8Kl6q_buy {
    padding: 0 16px;
  }
}

@media only screen and (max-width: 767px) {
  .A8Kl6q_buy {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 1023px) {
  .A8Kl6q_buy .A8Kl6q_inner span {
    display: none;
  }
}

.A8Kl6q_price {
  border-right: 1px solid #4493fc;
  margin-right: 20px;
  padding-right: 20px;
  line-height: 48px;
  box-shadow: 1px 0 #186fe3;
}

@media only screen and (max-width: 1023px) {
  .A8Kl6q_price {
    margin-right: 16px;
    padding-right: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .A8Kl6q_price {
    margin-right: 12px;
    padding-right: 12px;
  }
}

.aDs1sG_title {
  margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
  .aDs1sG_title {
    font-size: 24px;
  }
}

.aDs1sG_info {
  letter-spacing: -.02em;
  color: #6f767e;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .aDs1sG_info {
    font-size: 14px;
  }
}

.aDs1sG_line {
  align-items: center;
  display: flex;
}

.aDs1sG_author {
  color: #6f767e;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  display: flex;
}

.aDs1sG_author span {
  color: #1a1d1f;
  margin-left: 4px;
}

.dark-mode .aDs1sG_author span {
  color: #fcfcfc;
}

.aDs1sG_avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 12px;
}

.aDs1sG_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.aDs1sG_rating {
  align-items: center;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  display: flex;
}

.aDs1sG_rating svg {
  fill: #ffc554;
  margin-right: 8px;
}

.aDs1sG_counter {
  color: #6f767e;
  margin-left: 4px;
}

.aDs1sG_gallery {
  margin-top: 40px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .aDs1sG_gallery {
    margin-top: 32px;
  }
}

.aDs1sG_gallery .aDs1sG_button {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

@media only screen and (max-width: 767px) {
  .aDs1sG_gallery .aDs1sG_button {
    bottom: 5px;
  }
}

.dark-mode .aDs1sG_gallery .aDs1sG_button {
  color: #fcfcfc;
  background: #1a1d1f;
  box-shadow: inset 0 0 0 2px #272b30;
}

.dark-mode .aDs1sG_gallery .aDs1sG_button:hover {
  background: #272b30;
}

.aDs1sG_preview img {
  width: 100%;
  border-radius: 12px;
}

.aDs1sG_row {
  margin-top: 40px;
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .aDs1sG_row {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .aDs1sG_row {
    margin-top: 32px;
  }
}

.aDs1sG_col:first-child {
  flex-grow: 1;
  padding-right: 64px;
}

@media only screen and (max-width: 1259px) {
  .aDs1sG_col:first-child {
    padding-right: 48px;
  }
}

@media only screen and (max-width: 1023px) {
  .aDs1sG_col:first-child {
    padding-right: 0;
  }
}

.aDs1sG_col:nth-child(2) {
  width: 308px;
  flex-shrink: 0;
}

@media only screen and (max-width: 1023px) {
  .aDs1sG_col:nth-child(2) {
    width: 100%;
    margin-top: 32px;
  }
}

.aDs1sG_subtitle {
  margin-bottom: 32px;
}

@media only screen and (max-width: 1023px) {
  .aDs1sG_subtitle {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .aDs1sG_subtitle {
    margin-bottom: 12px;
  }
}

.aDs1sG_content {
  color: #33383f;
  font-weight: 500;
}

.dark-mode .aDs1sG_content {
  color: #efefef;
}

.aDs1sG_content p:not(:last-child) {
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .aDs1sG_features {
    margin-top: 12px;
  }
}

.aDs1sG_features li {
  color: #33383f;
  padding-left: 40px;
  position: relative;
}

.dark-mode .aDs1sG_features li {
  color: #efefef;
}

.aDs1sG_features li:before {
  content: "";
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%23b5e4ca'/%3E%3C/svg%3E") 50% / 100% no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.aDs1sG_features li:not(:last-child) {
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .aDs1sG_features li:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
}

.dark-mode .aDs1sG_features li:not(:last-child) {
  border-color: #272b30;
}

.Vcxu3G_products {
  border-top: 1px solid #efefef;
  margin-top: 64px;
  padding-top: 64px;
}

@media only screen and (max-width: 1023px) {
  .Vcxu3G_products {
    margin-top: 48px;
    padding-top: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .Vcxu3G_products {
    margin-top: 32px;
    padding-top: 32px;
  }
}

.dark-mode .Vcxu3G_products {
  border-color: #272b30;
}

.Vcxu3G_head {
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.Vcxu3G_title {
  margin-right: auto;
}

.Vcxu3G_wrapper {
  margin: 0 -12px;
}

@media only screen and (max-width: 767px) {
  .Vcxu3G_wrapper {
    margin: 0;
  }
}

.F-vIzW_comments {
  border-left: 1px solid #efefef;
  padding: 24px;
}

@media only screen and (max-width: 767px) {
  .F-vIzW_comments {
    padding: 16px 16px 24px;
  }
}

.dark-mode .F-vIzW_comments {
  border-color: #111315;
}

.F-vIzW_head {
  height: 48px;
  align-items: center;
  display: flex;
}

.F-vIzW_title {
  letter-spacing: -.02em;
  align-items: center;
  margin-right: auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .F-vIzW_title {
    font-size: 18px;
  }
}

.F-vIzW_counter {
  min-width: 38px;
  text-align: center;
  background: #cabdff;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 12px;
  padding: 0 6px;
  line-height: 36px;
}

.dark-mode .F-vIzW_counter {
  color: #1a1d1f;
}

.F-vIzW_close {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.F-vIzW_close svg {
  fill: #33383f;
  transition: fill .2s;
}

.dark-mode .F-vIzW_close svg {
  fill: #fcfcfc;
}

.F-vIzW_close:hover svg {
  fill: #2a85ff;
}

.F-vIzW_editor {
  margin-top: 24px;
}

.F-vIzW_list {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .F-vIzW_list {
    margin-top: 24px;
  }
}

.mSgsuW_item, .mSgsuW_answer {
  display: flex;
}

.mSgsuW_item .mSgsuW_avatar {
  width: 48px;
  height: 48px;
}

.mSgsuW_item .mSgsuW_details {
  width: calc(100% - 48px);
  flex: 0 0 calc(100% - 48px);
  padding-left: 16px;
}

.mSgsuW_item:not(:first-child) {
  border-top: 1px solid #efefef;
  margin-top: 32px;
  padding-top: 24px;
}

.dark-mode .mSgsuW_item:not(:first-child) {
  border-color: #272b30;
}

.mSgsuW_answer {
  border-top: 1px solid #efefef;
  margin-top: 32px;
  padding: 24px 0 0 64px;
}

.dark-mode .mSgsuW_answer {
  border-color: #272b30;
}

.mSgsuW_answer .mSgsuW_avatar {
  width: 32px;
  height: 32px;
}

.mSgsuW_answer .mSgsuW_details {
  width: calc(100% - 32px);
  flex: 0 0 calc(100% - 32px);
  padding-left: 16px;
}

.mSgsuW_avatar {
  flex-shrink: 0;
}

.mSgsuW_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.mSgsuW_line {
  align-items: center;
  display: flex;
}

.mSgsuW_author {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: auto;
  overflow: hidden;
}

.mSgsuW_time {
  white-space: nowrap;
  color: #9a9fa5;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33333;
}

.mSgsuW_rating {
  align-items: center;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  display: flex;
}

.mSgsuW_rating svg {
  fill: #ffc554;
  margin-left: 8px;
}

.mSgsuW_login {
  color: #6f767e;
  margin-top: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23077;
}

.mSgsuW_comment {
  color: #33383f;
  margin-top: 12px;
  font-weight: 500;
}

.dark-mode .mSgsuW_comment {
  color: #fcfcfc;
}

.mSgsuW_comment strong {
  font-weight: 600;
}

.ANKRBG_control {
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.ANKRBG_favorite, .ANKRBG_reply {
  color: #9a9fa5;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.23077;
  transition: color .2s;
  display: flex;
}

.ANKRBG_favorite svg, .ANKRBG_reply svg {
  fill: #9a9fa5;
  margin-right: 10px;
  transition: fill .2s;
}

.ANKRBG_favorite:hover, .ANKRBG_favorite.ANKRBG_active, .ANKRBG_reply:hover, .ANKRBG_reply.ANKRBG_active {
  color: #1a1d1f;
}

.ANKRBG_favorite:hover svg, .ANKRBG_favorite.ANKRBG_active svg, .ANKRBG_reply:hover svg, .ANKRBG_reply.ANKRBG_active svg {
  fill: #1a1d1f;
}

.dark-mode .ANKRBG_favorite:hover, .dark-mode .ANKRBG_favorite.ANKRBG_active, .dark-mode .ANKRBG_reply:hover, .dark-mode .ANKRBG_reply.ANKRBG_active {
  color: #fcfcfc;
}

.dark-mode .ANKRBG_favorite:hover svg, .dark-mode .ANKRBG_favorite.ANKRBG_active svg, .dark-mode .ANKRBG_reply:hover svg, .dark-mode .ANKRBG_reply.ANKRBG_active svg {
  fill: #fcfcfc;
}

.ANKRBG_favorite:not(:last-child), .ANKRBG_reply:not(:last-child) {
  margin-right: 24px;
}

.ANKRBG_favorite svg:nth-child(2), .ANKRBG_favorite.ANKRBG_active svg:first-child {
  display: none;
}

.ANKRBG_favorite.ANKRBG_active svg:nth-child(2) {
  display: inline-block;
}

.ANKRBG_answer.ANKRBG_show {
  display: block;
}

.w52Aka_tooltipWpapper {
  border-radius: 4px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), inset 0 0 1px #000;
  padding: 3px 12px !important;
  font-size: 12px !important;
}

.nztt_G_label {
  color: #33383f;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
}

.dark-mode .nztt_G_label {
  color: #efefef;
}

.nztt_G_tooltip {
  position: relative;
  top: -1px;
}

.nztt_G_tooltip svg {
  fill: #9a9fa5;
}

.nztt_G_editor {
  font-family: Inter, sans-serif;
  position: relative;
}

.dark-mode .nztt_G_editor {
  color: #111315;
}

.nztt_G_editor .nztt_G_editorMain {
  min-height: 112px;
}

.dark-mode .nztt_G_editor .nztt_G_editorMain {
  color: #fcfcfc;
}

.nztt_G_editor .nztt_G_editorWrapper {
  background: #f4f4f4;
  border: 2px solid rgba(154, 159, 165, .25);
  border-radius: 12px;
}

.dark-mode .nztt_G_editor .nztt_G_editorWrapper {
  background: #111315;
  border-color: rgba(154, 159, 165, .15);
}

.nztt_G_editor .nztt_G_editorToolbar {
  background: #fcfcfc;
  border: none;
  border-radius: 12px 12px 0 0;
}

.dark-mode .nztt_G_editor .nztt_G_editorToolbar {
  background: #272b30;
}

.nztt_G_editorButton .nztt_G_editorMain {
  padding-bottom: 56px;
}

.nztt_G_editorButton .nztt_G_button {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.rdw-option-wrapper {
  min-width: 25px;
  height: 20px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 5px;
  display: flex;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-option-wrapper:active, .rdw-option-active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-option-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-dropdown-wrapper {
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-dropdown-wrapper:focus {
  outline: none;
}

.rdw-dropdown-wrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdown-wrapper:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-dropdown-carettoopen {
  height: 0;
  width: 0;
  border-top: 6px solid #000;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-carettoclose {
  height: 0;
  width: 0;
  border-bottom: 6px solid #000;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-selectedtext {
  height: 100%;
  align-items: center;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.rdw-dropdown-optionwrapper {
  z-index: 100;
  width: 98%;
  max-height: 250px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: scroll;
}

.rdw-dropdown-optionwrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdownoption-default {
  min-height: 25px;
  align-items: center;
  padding: 0 5px;
  display: flex;
}

.rdw-dropdownoption-highlighted {
  background: #f1f1f1;
}

.rdw-dropdownoption-active {
  background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-inline-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-inline-dropdown {
  width: 50px;
}

.rdw-inline-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-block-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-block-dropdown {
  width: 110px;
}

.rdw-fontsize-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontsize-dropdown {
  min-width: 40px;
}

.rdw-fontsize-option {
  justify-content: center;
  display: flex;
}

.rdw-fontfamily-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontfamily-dropdown {
  width: 115px;
}

.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rdw-fontfamily-optionwrapper {
  width: 140px;
}

.rdw-list-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-list-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-text-align-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-text-align-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-right-aligned-block {
  text-align: right;
}

.rdw-left-aligned-block {
  text-align: left !important;
}

.rdw-center-aligned-block {
  text-align: center !important;
}

.rdw-justify-aligned-block {
  text-align: justify !important;
}

.rdw-right-aligned-block > div, .rdw-left-aligned-block > div, .rdw-center-aligned-block > div, .rdw-justify-aligned-block > div {
  display: inline-block;
}

.rdw-colorpicker-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-colorpicker-modal {
  width: 175px;
  height: 175px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-colorpicker-modal-header {
  padding-bottom: 5px;
  display: flex;
}

.rdw-colorpicker-modal-style-label {
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
  font-size: 15px;
}

.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}

.rdw-colorpicker-modal-options {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin: 5px auto;
  display: flex;
  overflow: scroll;
}

.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #f1f1f1;
}

.rdw-colorpicker-option {
  min-height: 20px;
  width: 22px;
  height: 22px;
  min-width: 22px;
  border: none;
  margin: 3px;
  padding: 0;
  box-shadow: inset 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #bfbdbd;
}

.rdw-colorpicker-option-active {
  box-shadow: 0 0 2px 2px #bfbdbd;
}

.rdw-link-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-link-dropdown {
  width: 50px;
}

.rdw-link-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}

.rdw-link-modal {
  width: 235px;
  height: 205px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-link-modal-label {
  font-size: 15px;
}

.rdw-link-modal-input {
  height: 25px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0 5px;
}

.rdw-link-modal-input:focus {
  outline: none;
}

.rdw-link-modal-buttonsection {
  margin: 0 auto;
}

.rdw-link-modal-target-option {
  margin-bottom: 20px;
}

.rdw-link-modal-target-option > span {
  margin-left: 5px;
}

.rdw-link-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 10px;
}

.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:focus {
  outline: none !important;
}

.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-link-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-embedded-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-embedded-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}

.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-header-label {
  width: 95px;
  background: #6eb8d4;
  border: 1px solid #f1f1f1;
  border-bottom: 2px solid #0a66b7;
  margin-top: 5px;
}

.rdw-embedded-modal-link-section {
  flex-direction: column;
  display: flex;
}

.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 10px 0;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-embedded-modal-link-input-wrapper {
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-link-input:focus {
  outline: none;
}

.rdw-embedded-modal-btn-section {
  justify-content: center;
  display: flex;
}

.rdw-embedded-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:focus {
  outline: none !important;
}

.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-embedded-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-embedded-modal-size-input:focus {
  outline: none;
}

.rdw-emoji-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-emoji-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  overflow: auto;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-emoji-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  font-size: 22px;
  display: flex;
}

.rdw-spinner {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  display: inline-block;
}

.rdw-spinner .rdw-bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s;
}

.rdw-spinner .rdw-bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.rdw-image-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-image-modal {
  width: 235px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-image-modal-header {
  margin: 10px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  outline-offset: -10px;
  background-color: #f1f1f1;
  border: none;
  outline: 2px dashed gray;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 9px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-label span {
  padding: 0 20px;
}

.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px;
}

.rdw-image-modal-upload-option-input {
  width: .1px;
  height: .1px;
  opacity: 0;
  z-index: -1;
  position: absolute;
  overflow: hidden;
}

.rdw-image-modal-url-section {
  align-items: center;
  display: flex;
}

.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 15px 0 12px;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}

.rdw-image-modal-url-input:focus {
  outline: none;
}

.rdw-image-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 5px;
}

.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:focus {
  outline: none !important;
}

.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-image-modal-spinner {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: absolute;
  top: -3px;
  left: 0;
}

.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 5px;
  font-size: 12px;
}

.rdw-image-modal-alt-input:focus {
  outline: none;
}

.rdw-image-modal-alt-lbl {
  font-size: 12px;
}

.rdw-image-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-image-modal-size-input:focus {
  outline: none;
}

.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}

.rdw-remove-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-history-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-history-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-link-decorator-wrapper {
  position: relative;
}

.rdw-link-decorator-icon {
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 40%;
}

.rdw-mention-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-suggestion-wrapper {
  position: relative;
}

.rdw-suggestion-dropdown {
  min-width: 100px;
  max-height: 150px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow: auto;
}

.rdw-suggestion-option {
  border-bottom: 1px solid #f1f1f1;
  padding: 7px 5px;
}

.rdw-suggestion-option-active {
  background-color: #f1f1f1;
}

.rdw-hashtag-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-image-alignment-options-popup {
  width: 105px;
  cursor: pointer;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  padding: 5px 2px;
  display: flex;
  position: absolute;
}

.rdw-alignment-option-left {
  justify-content: flex-start;
}

.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}

.rdw-image-alignment, .rdw-image-imagewrapper {
  position: relative;
}

.rdw-image-center {
  justify-content: center;
  display: flex;
}

.rdw-image-left {
  display: flex;
}

.rdw-image-right {
  justify-content: flex-end;
  display: flex;
}

.rdw-image-alignment-options-popup-right {
  right: 0;
}

.rdw-editor-main {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.rdw-editor-toolbar {
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 6px 5px 0;
  font-size: 15px;
  display: flex;
}

.public-DraftStyleDefault-block {
  margin: 1em 0;
}

.rdw-editor-wrapper:focus {
  outline: none;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
}

.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}

.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
  border-left: .1px solid rgba(0, 0, 0, 0);
  position: relative;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  text-align: left;
  left: 0;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  text-align: right;
  right: 0;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  z-index: 0;
  position: absolute;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  white-space: pre-wrap;
  position: relative;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  text-align: right;
  width: 30px;
  position: absolute;
  left: -36px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  text-align: left;
  width: 30px;
  position: absolute;
  right: -36px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.mny6tq_panel {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 16px;
  left: calc(100% + 24px);
}

@media only screen and (max-width: 1259px) {
  .mny6tq_panel {
    top: 0;
    left: auto;
    right: -88px;
  }
}

@media only screen and (max-width: 767px) {
  .mny6tq_panel {
    display: none;
  }
}

.mny6tq_avatar:not(:last-child), .mny6tq_brand:not(:last-child), .mny6tq_share:not(:last-child) {
  margin-bottom: 24px;
}

.mny6tq_avatar, .mny6tq_brand, .mny6tq_share {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
}

.mny6tq_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.mny6tq_brand {
  background: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.dark-mode .mny6tq_brand {
  background: #272b30;
}

.mny6tq_brand img {
  max-height: 32px;
}

.mny6tq_counter {
  min-width: 20px;
  color: #fcfcfc;
  background: #1a1d1f;
  border-radius: 50%;
  padding: 0 4px;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.dark-mode .mny6tq_counter {
  color: #1a1d1f;
  background: #fcfcfc;
}

.mny6tq_share {
  background: #fff;
  border-radius: 50%;
}

.dark-mode .mny6tq_share {
  background: #272b30;
}

.mny6tq_share svg {
  fill: #6f767e;
  transition: fill .2s;
}

.mny6tq_share:hover svg {
  fill: #2a85ff;
}

.UQZRgG_modal {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
  background: rgba(244, 244, 244, .8);
  padding: 48px;
  -webkit-animation: UQZRgG_showModal .4s forwards;
  animation: UQZRgG_showModal .4s forwards;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .UQZRgG_modal {
    padding: 16px;
  }
}

.dark-mode .UQZRgG_modal {
  background: rgba(39, 43, 48, .9);
}

@-webkit-keyframes UQZRgG_showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes UQZRgG_showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.UQZRgG_outer {
  width: 100%;
  max-width: 408px;
  z-index: 2;
  background: #fcfcfc;
  border-radius: 16px;
  margin: auto;
  position: relative;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, .05), 0 32px 48px -8px rgba(0, 0, 0, .1);
}

.dark-mode .UQZRgG_outer {
  background: #1a1d1f;
}

@media only screen and (max-width: 767px) {
  .UQZRgG_outer {
    box-shadow: none;
  }
}

.UQZRgG_outer > div {
  padding: 26px 24px 24px;
}

.UQZRgG_close {
  width: 36px;
  height: 36px;
  background: #efefef;
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  top: 24px;
  right: 24px;
}

.UQZRgG_close svg {
  fill: #33383f;
  transition: -webkit-transform .2s, transform .2s;
}

.dark-mode .UQZRgG_close {
  background: #272b30;
}

.dark-mode .UQZRgG_close svg {
  fill: #fcfcfc;
}

.UQZRgG_close:hover svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.vKOl0q_head, .vKOl0q_preview {
  border-bottom: 1px solid #efefef;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.dark-mode .vKOl0q_head, .dark-mode .vKOl0q_preview {
  border-color: #272b30;
}

.vKOl0q_title {
  position: 40px;
}

.vKOl0q_field {
  margin-bottom: 24px;
  position: relative;
}

.vKOl0q_icon {
  width: 40px;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.vKOl0q_icon svg {
  fill: #6f767e;
}

.vKOl0q_input {
  width: 100%;
  height: 40px;
  color: #9a9fa5;
  background: #f4f4f4;
  border-radius: 8px;
  padding: 0 60px 0 44px;
  font-size: 15px;
  font-weight: 600;
}

.dark-mode .vKOl0q_input {
  background: #272b30;
}

.vKOl0q_copy {
  color: #2a85ff;
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
}

.vKOl0q_copy:hover {
  opacity: .8;
}

.vKOl0q_preview img {
  width: 100%;
  border-radius: 12px;
}

.vKOl0q_btns {
  margin: 0 -8px;
  display: flex;
}

.vKOl0q_btns .vKOl0q_button {
  flex-grow: 1;
  margin: 0 8px;
}

._thfSW_follower {
  align-items: flex-start;
  padding-bottom: 24px;
  display: flex;
}

._thfSW_follower:not(:last-child) {
  border-bottom: 1px solid #efefef;
  margin-bottom: 24px;
}

.dark-mode ._thfSW_follower:not(:last-child) {
  border-color: #272b30;
}

._thfSW_details {
  flex-grow: 1;
  padding-right: 24px;
  display: flex;
}

@media only screen and (max-width: 1023px) {
  ._thfSW_details {
    padding-right: 0;
  }
}

._thfSW_avatar {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 16px;
}

@media only screen and (max-width: 767px) {
  ._thfSW_avatar {
    width: 64px;
    height: 64px;
  }
}

._thfSW_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

._thfSW_man {
  letter-spacing: -.02em;
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  ._thfSW_man {
    font-size: 18px;
  }
}

._thfSW_list {
  align-items: center;
  display: flex;
}

._thfSW_counter {
  color: #9a9fa5;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23077;
}

._thfSW_counter span {
  color: #6f767e;
}

._thfSW_counter:not(:first-child) {
  margin-left: 12px;
  padding-left: 14px;
  position: relative;
}

._thfSW_counter:not(:first-child):before {
  content: "";
  width: 2px;
  height: 8px;
  background: #efefef;
  border-radius: 2px;
  position: absolute;
  top: 5px;
  left: 0;
}

.dark-mode ._thfSW_counter:not(:first-child):before {
  background: #272b30;
}

._thfSW_gallery {
  width: 480px;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin: -12px -6px 0 0;
  display: flex;
}

@media only screen and (max-width: 1023px) {
  ._thfSW_gallery {
    display: none;
  }
}

._thfSW_preview {
  width: calc(33.333% - 12px);
  height: 116px;
  flex: 0 0 calc(33.333% - 12px);
  margin: 12px 6px 0;
}

._thfSW_preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

._thfSW_preview:nth-child(n+4) {
  display: none;
}

._thfSW_btns {
  margin-top: 16px;
  display: flex;
}

._thfSW_btns ._thfSW_button._thfSW_follow span {
  display: none;
}

._thfSW_btns ._thfSW_button._thfSW_follow._thfSW_active {
  background: #efefef;
  box-shadow: inset 0 0 0 2px #efefef;
}

.dark-mode ._thfSW_btns ._thfSW_button._thfSW_follow._thfSW_active {
  background: #272b30;
  box-shadow: inset 0 0 0 2px #33383f;
}

._thfSW_btns ._thfSW_button._thfSW_follow._thfSW_active span {
  display: inline;
}

._thfSW_btns ._thfSW_button:not(:last-child) {
  margin-right: 8px;
}

/*# sourceMappingURL=index.d7aca877.css.map */
